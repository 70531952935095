import React from 'react';
import cc from 'classcat';
import styles from './ButtonGroupSelect.module.scss';
import { TagButtonGroup } from '../TagButtonGroup/TagButtonGroup';

type Props = {
  items: Record<string, string>;
  value: Array<string | number> | null;
  placeholder?: string;
  onChange: (newValue: Array<string | number> | null) => void;
  multiselect?: boolean;
  disabled?: boolean;
};

export const ButtonGroupSelect = ({
  multiselect = false,
  items,
  value,
  placeholder = 'Выбрать',
  onChange,
  disabled,
}: Props) => {
  const handleChangeMultiselect = (newValue: string) => {
    if (value === null) {
      onChange([newValue]);
      return;
    }

    if (value.includes(newValue)) {
      const newValueArray = value.filter((i) => i !== newValue);
      onChange(newValueArray.length > 0 ? newValueArray : null);
      return;
    }

    onChange([...value, newValue]);
  };

  const handleChangeSingle = (newValue: string) => {
    if (value === null || !value.includes(newValue)) {
      onChange([newValue]);
    } else {
      onChange(null);
    }
  };

  return (
    <div className={cc([styles.root, { [styles.disabled]: disabled }])}>
      <div className={styles.buttonGroup}>
        {Object.entries(items).map(([itemValue, item]) => {
          const isSelected = value ? value.includes(itemValue) : false;
          return (
            <TagButtonGroup
              key={itemValue}
              onClick={() => {
                multiselect
                  ? handleChangeMultiselect(itemValue)
                  : handleChangeSingle(itemValue);
              }}
              isSelected={isSelected}
              disabled={disabled}
            >
              {item}
            </TagButtonGroup>
          );
        })}
      </div>
      {/*{(!value || value.length === 0) && (*/}
      {/*  <div className={styles.placeholder}>{placeholder}</div>*/}
      {/*)}*/}
    </div>
  );
};

