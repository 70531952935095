import React from 'react';
import { OverlayZoomButton } from 'components/atoms/OverlayZoomButton';
import {
  ApartmentDto,
  getAllApartmentPlans,
  getAllApartmentPolygons,
} from 'services';
import { MayBe } from 'types';
import { PolygonCoordinates } from 'types/PolygonCoordinates';
import { useShowApartmentPopup } from 'pages/LayoutPage/containers/ShowApartmentPopup';
import { LevelStepper } from '../LevelStepper/LevelStepper';
import styles from './CottagePlan.module.scss';
import { useApartmentPlanOnMap } from '../../../hooks/useApartmentPlanOnMap';
import { useCottagePlanPopup } from '../CottagePlanPopup/CottagePlanPopup';
import { Cottage } from "../../../../../../../services/v1/villageService/types";

type Props = {
  apartment: Cottage | undefined;
};

export const CottagePlan = ({ apartment }: Props) => {
  const apartmentPlans: MayBe<string>[] = getAllApartmentPlans(apartment);

  const {
    currentPlan,
    isShowLevelStepper,
    count,
    onDecrement,
    onIncrement,
    maxLevel,
  } = useApartmentPlanOnMap({
    plans: apartmentPlans,
  });
  const { showApartmentPlanPopup } = useCottagePlanPopup(apartment, count);

  return (
    <div className={styles.wrapper}>
      <OverlayZoomButton
        className={styles.planWrapper}
        onClick={showApartmentPlanPopup}
      >
        <img className={styles.plan} src={currentPlan || ''} alt="" />
      </OverlayZoomButton>
      {isShowLevelStepper && (
        <LevelStepper
          count={count}
          onDecrement={onDecrement}
          onIncrement={onIncrement}
          minCount={1}
          maxCount={maxLevel}
        />
      )}
    </div>
  );
};
