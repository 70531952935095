import React from 'react';
import { useInitApartmentSidebar } from 'pages/LayoutPage/hooks/useInitApartmentSidebar';
import { apartmentsService } from 'services';
import { observer } from 'mobx-react';
import { complexItemStore } from 'stores/ComplexItemStore/store';
import { CottageViewPopup } from './CottageViewPopup/CottageViewPopup';
import { CottageViewContent } from './CottageViewContent/CottageViewContent';
import {Cottage} from "../../../../../../services/v1/villageService/types";

type Props = {
  apartment?: Cottage;
  onClose?: () => void;
  isOpen: boolean;
};

export const CottageViewRender = ({ apartment, onClose, isOpen }: Props) => {
  const { target: complexData } = complexItemStore;
  // const { target: complexData } = cottageItemStore;

  // const { state } = rootApartmentStore.apartmentAccessStatusStore;

  useInitApartmentSidebar({ apartmentsService });

  // if (!state.isAccessToView) {
  //   return null;
  // }

  return (
    <CottageViewPopup
      number={apartment?.number}
      isOpen={isOpen}
      onClose={onClose}
    >
      <CottageViewContent apartment={apartment} complex={complexData} />
    </CottageViewPopup>
  );
};

export const CottageView = observer(CottageViewRender);
