import React, { memo } from 'react';

import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { requestApiInstance } from 'stores/request/RequestApiStore';

import { ReactiveInput } from './ReactiveInput';

import styles from '../../AllFilters/AllFilters.module.scss';
import { BuildingFiltersStore } from 'stores';
import {ReactiveButtonGroupSelect} from "../../../atoms/ReactiveButtonGroupSelect/ReactiveButtonGroupSelect";
import ReactiveCheckbox from "../villages/ReactiveCheckbox";

type Props = {
  isShowFromComplex?: boolean,
  store?: BuildingFiltersStore['filters'],
}

export const FirstColumnFilter = memo(({isShowFromComplex, store}:Props ) => {
  const { districts } = infoStore;
  const { isLoading } = requestApiInstance;

  return (
    <>
      <span className={styles.formLabel}>Стоимость</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceMin"
          placeholder=""
          prefixText="от"
          suffixIcon="₽"
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceMax"
          placeholder=""
          prefixText="до"
          suffixIcon="₽"
          clearable={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип помещения</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="roomType"
          placeholder="Любой"
          dictKey="apartment_type_room"
          multiselect={true}
        />
      </div>

      <span className={styles.formLabel}>Этаж</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="floorMin"
          prefixText="Не ниже"
          placeholder=''
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="floorMax"
          prefixText="Не выше"
          placeholder=''
          clearable={true}
        />
      </div>

      <div className={styles.floorsCheckboxes}>
        <ReactiveCheckbox
          store={store || complexFilterStore.filters}
          filterKey="notFirstFloor"
          placeholder="Не первый"
          className={styles.floorsCheckbox}
        />

        <ReactiveCheckbox
          store={store || complexFilterStore.filters}
          filterKey="notLastFloor"
          placeholder="Не последний"
          className={styles.floorsCheckbox}
        />

        <ReactiveCheckbox
          store={store || complexFilterStore.filters}
          filterKey="isLastFloor"
          placeholder="Только последний"
          className={styles.floorsCheckbox}
        />

        <ReactiveCheckbox
          store={store || complexFilterStore.filters}
          filterKey="isPenthouse"
          placeholder="Пентхаус"
          className={styles.floorsCheckbox}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Способ оплаты</span>
        <ReactiveButtonGroupSelect
          store={complexFilterStore.filters}
          filterKey="paymentType"
          dictKey="residential_complex_payment_type"
          multiselect={true}
        />
      </div>


      <span className={styles.formLabel}>Стоимость (м²)</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceM2Min"
          placeholder=""
          prefixText="от"
          suffixIcon="₽"
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceM2Max"
          placeholder=""
          prefixText="до"
          suffixIcon="₽"
          clearable={true}
        />
      </div>

      <span className={styles.formLabel}>Общая площадь (м²)</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareMin"
          placeholder=""
          prefixText="от"
          suffixIcon="м²"
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareMax"
          placeholder=""
          prefixText="до"
          suffixIcon="м²"
          clearable={true}
        />
      </div>

      <span className={styles.formLabel}>Площадь кухни (м²)</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareKitchenMin"
          placeholder=""
          prefixText="от"
          suffixIcon="м²"
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareKitchenMax"
          placeholder=""
          prefixText="до"
          suffixIcon="м²"
          clearable={true}
        />
      </div>

      <span className={styles.formLabel}>Жилая площадь (м²)</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareLivingMin"
          placeholder=""
          prefixText="от"
          suffixIcon="м²"
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareLivingMax"
          placeholder=""
          prefixText="до"
          suffixIcon="м²"
          clearable={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Вид</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="windowType"
          dictKey="apartment_window_type"
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Потолки</span>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="ceilingHeightMin"
          placeholder=""
          prefixText="от"
          suffixIcon="м²"
          clearable={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Отделка</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="finishing"
          dictKey="residential_complex_finishing"
          multiselect={true}
        />
      </div>

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Район</span>*/}
      {/*  {districts && (*/}
      {/*    <PopupDistrictsChoicer*/}
      {/*      data={districts}*/}
      {/*      filterKey="districtIds"*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      disabled={isLoading || isShowFromComplex}*/}
      {/*      placeholder="Любой"*/}
      {/*    />*/}
      {/*  )}*/}
      {/*</div>*/}

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Тип дома</span>*/}
      {/*  <ReactiveSelect*/}
      {/*    store={complexFilterStore.filters}*/}
      {/*    filterKey="houseType"*/}
      {/*    dictKey="residential_complex_house_type"*/}
      {/*    placeholder="Не важно"*/}
      {/*    disabled={isShowFromComplex}*/}
      {/*  />*/}
      {/*</div>*/}

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Тип квартиры</span>*/}
      {/*  <ReactiveSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    filterKey="rooms"*/}
      {/*    placeholder="Любой"*/}
      {/*    dictKey="rooms"*/}
      {/*  />*/}
      {/*</div>*/}
    </>
  );
});
