import React, { useState, useRef, useEffect } from 'react';
import cc from 'classcat';
import { AlbumFilterOption } from 'stores/AlbumsStore';
import { AlbumPhotoToFilter } from 'services';
import folderIcon from 'assets/folderIcon.svg';
import downArrowIcon from 'assets/arrowDropdownWhite.svg';
import styles from './styles.module.css';

type Props = {
  albumFilterOptions: AlbumFilterOption[];
  slideToAlbums: (albumId: number) => void;
  activeIndex: number;
  photosToShow: AlbumPhotoToFilter[];
};

export const AlbumDropdownTabs = ({
  albumFilterOptions,
  photosToShow,
  slideToAlbums,
  activeIndex,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentAlbumId = photosToShow[activeIndex]?.albumId;
  const currentAlbum = albumFilterOptions.find(
    (album) => album.albumId === currentAlbumId,
  );

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !(dropdownRef.current as any).contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelect = (albumId: number) => {
    slideToAlbums(albumId);
    setIsOpen(false);
  };

  return (
    <div className={styles.albumDropdown} ref={dropdownRef}>
      <div className={styles.albumDropdownName}>
        Альбомы
      </div>
      <div className={styles.albumDropdownLine}></div>
      <div
        className={styles.albumDropdownHeader}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img src={folderIcon} alt="Folder Icon" className={styles.folderIcon} />
        <span className={styles.albumName}>{currentAlbum?.title}</span>
        <img
          src={downArrowIcon}
          alt="Down Arrow"
          className={cc([
            styles.arrowIcon,
            { [styles.arrowIconOpen]: isOpen },
          ])}
        />
      </div>
      {isOpen && (
        <div className={styles.albumDropdownList}>
          {albumFilterOptions.map(({ albumId, title }) => (
            <div
              key={albumId}
              className={styles.albumDropdownItem}
              onClick={() => handleSelect(albumId)}
            >
              {title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
