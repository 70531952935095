import React from 'react';
import {useTheme} from "../../context/theme/useTheme";

const ArrowLeftIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8L2 8M2 8L7.25 14M2 8L7.25 2" stroke="#585B68" strokeWidth="2"/>
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16 8L2 8M2 8L7.25 14M2 8L7.25 2" stroke="#AFB4CB" strokeWidth="2"/>
      </svg>
    )
  );
};

export default ArrowLeftIcon;
