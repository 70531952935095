import React from 'react';
import { useTheme } from "../../context/theme/useTheme";

const LinkIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 15L15 9" stroke="#E1E4EC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M10.3337 8.21215L13.5154 5.03049C14.9909 3.55494 17.3833 3.55494 18.8588 5.03049C20.3344 6.50604 20.3344 8.89839 18.8588 10.3739L15.6771 13.5556L17.0914 14.9698L20.273 11.7882C22.5296 9.53155 22.5296 5.87288 20.273 3.61628C18.0164 1.35968 14.3577 1.35968 12.1011 3.61628L8.91948 6.79794L10.3337 8.21215ZM8.21238 10.3335L6.79816 8.91926L3.35065 12.3668C1.09405 14.6234 1.09405 18.282 3.35065 20.5386C5.60725 22.7952 9.26593 22.7952 11.5225 20.5386L14.97 17.0911L13.5558 15.6769L10.1083 19.1244C8.63276 20.6 6.24042 20.6 4.76487 19.1244C3.28932 17.6489 3.28932 15.2565 4.76487 13.781L8.21238 10.3335Z"
              fill="#E1E4EC"/>
      </svg>
    ) : (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 15L15 9" stroke="#333338" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M10.3337 8.21215L13.5154 5.03049C14.9909 3.55494 17.3833 3.55494 18.8588 5.03049C20.3344 6.50604 20.3344 8.89839 18.8588 10.3739L15.6771 13.5556L17.0914 14.9698L20.273 11.7882C22.5296 9.53155 22.5296 5.87288 20.273 3.61628C18.0164 1.35968 14.3577 1.35968 12.1011 3.61628L8.91948 6.79794L10.3337 8.21215ZM8.21238 10.3335L6.79816 8.91926L3.35065 12.3668C1.09405 14.6234 1.09405 18.282 3.35065 20.5386C5.60725 22.7952 9.26593 22.7952 11.5225 20.5386L14.97 17.0911L13.5558 15.6769L10.1083 19.1244C8.63276 20.6 6.24042 20.6 4.76487 19.1244C3.28932 17.6489 3.28932 15.2565 4.76487 13.781L8.21238 10.3335Z"
              fill="#333338"/>
      </svg>
    )
  );
};

export default LinkIcon;
