import React, { useMemo, useState } from 'react';
import { flow, get } from 'lodash/fp';
import { observer } from 'mobx-react';
import {
  Text,
  DictionaryView,
  NumberFormat,
  DictionaryBadge,
  PlaceholderImage,
} from 'components/atoms';
import {
  ApartmentDto,
  DictionaryKeys,
  getAllApartmentPlans,
  getApartmentPreview,
} from 'services';
import { MayBe } from 'types';
import { FavoritesAddButton } from 'components/containers/FavoritesAddButton';
import { OffersStatusAndAddButton } from 'components/containers/OffersStatusAndAddButton';
import { CottagePlan } from '../CottagePlan/CottagePlan';
import { CottageSquareInfo } from '../CottageSquareInfo/CottageSquareInfo';
import { Cottage } from "../../../../../../../services/v1/villageService/types";
import { PdfButton } from "../../../../../../../components/containers/PdfButton";
import LinkIcon from "../../../../../../../components/ui/LinkIcon";
import styles from './CottageHeader.module.scss';

type Props = {
  apartment?: Cottage;
};

export const CottageHeaderRenderer = ({ apartment }: Props) => {
  const [currentLayoutIndex, setCurrentLayoutIndex] = useState(0);

  const apartmentTypeRoomId = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('village_type_house'),
      )(apartment),
    [apartment],
  );
  const apartment_status = flow<any, Record<DictionaryKeys, number[]>, number[]>(
    get('catalogs'),
    get('apartment_status'),
  )(apartment);

  const apartmentPreview: MayBe<string> = useMemo(
    () => getApartmentPreview(apartment),
    [apartment],
  );
  const apartmentPlans: MayBe<string>[] = getAllApartmentPlans(apartment);



  return (
    <header>
      <div className={styles.topWrapper}>
        <div className={styles.logo}>
          {/*{apartment?.layouts && apartment?.layouts[currentLayoutIndex]?.photo && (*/}
          {/*  <img*/}
          {/*    className={styles.logoImage}*/}
          {/*    src={apartment?.layouts[currentLayoutIndex]?.photo}*/}
          {/*    alt="apartment preview"*/}
          {/*  />*/}
          {/*)}*/}

          {apartment?.layouts && apartment?.layouts.length > 0 && (
            <CottagePlan apartment={apartment} />
          )}
          {apartment?.layouts && apartment?.layouts.length === 0 && (
            <PlaceholderImage type="FLOOR_PLAN" />
          )}
        </div>

        <div className={styles.topRight}>
          <div className={styles.infoWrapper}>
            <div>
              <DictionaryBadge
                badgeInner
                dictKey="apartment_status"
                dictItemId={apartment_status}
                className={styles.infoWrapperStatus}
              />
              <Text
                align="left"
                display="block"
                className={styles.infoWrapperType}
              >
                <DictionaryView
                  dictKey="village_type_house"
                  dictItemId={apartmentTypeRoomId}
                />
              </Text>
              <Text
                className={styles.price_m2}
                align="right"
                lineHeight="XL"
                size="XS"
                display="block"
                weight="bold"
              >
                <NumberFormat value={apartment?.price_m2} format="rub_meter2" />
              </Text>
              <Text className={styles.price} align="right" size="L" display="block" weight="bold">
                <NumberFormat value={apartment?.price} />
              </Text>
            </div>

            {/*<div className={styles.infoWrapperPrice}>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>

      {apartment?.id && (
        <div className={styles.actionButtonsWrapper}>
          <OffersStatusAndAddButton apartmentId={apartment.id} className={styles.headerBtnIcon} headerMenu={true}/>
          <FavoritesAddButton apartmentId={apartment.id} className={styles.headerBtnIcon} headerMenu={true}/>
          <button className={styles.headerBtnIcon}><LinkIcon/></button>
          <PdfButton className={styles.headerBtnIcon}/>
          <button className={styles.btnReadMore}>Подробнее</button>
        </div>
      )}

      <div className={styles.squareListWrapper}>
      <CottageSquareInfo apartment={apartment} />
      </div>
    </header>
  );
};

export const CottageHeader = observer(CottageHeaderRenderer);

