import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import {appRoutesService, Params, userServiceStorage} from 'services';
import cc from 'classcat';
import { MediaQuery } from 'components/containers/MediaQuery';
import { LayoutPageHeaderDesktop } from '../../containers/LayoutPageHeaderDesktop';
import { useLayoutTabsState } from '../../hooks/useLayoutTabsState';
import { useAllFiltersPopup } from 'components/containers/AllFiltersPopup';
import {buildingFilters, complexFilterStore, villageStore} from 'stores';
import { GeneralPlanTable } from './components/GeneralPlanTable/GeneralPlanTable';
import styles from './styles.module.scss';
import { NoResults } from './components/NoResults/NoResults';
import { GeneralPlanMap } from "./components/GeneralPlanMap/GeneralPlanMap";
import { useHistory } from "../../../../hooks";
import { AuthPage } from "../../../AuthPage";
import { AuthForGetAccessToVillage } from "../AuthForGetAccessToVillage";
import { authRoutesStore } from "../../../../stores/user/AuthStore/AuthRoutes/AuthRoutes";
import { toJS } from "mobx";

export const GeneralPlanVillage: React.FC = observer(() => {
  const { complexId, generalPlanId, viewType, sectionId, cottageId, cottageNumber } =
    useParams<Pick<Params, 'complexId' | 'generalPlanId' | 'viewType' | 'sectionId' | 'cottageId' | 'cottageNumber'>>();
  const history = useHistory();
  const villageId = complexId || '';
  const [currentActiveTabNew, setCurrentActiveTabNew] = useState<number>(0);
  const [selectedSectionId, setSelectedSectionId] = useState<number | null>(Number(sectionId) || null);
  const user = userServiceStorage.getUser();
  const filtersForAPI = toJS(buildingFilters.filters.filterToApiFormat);
  const sections = villageStore?.villageDetails?.sections || [];

  const { tabList, changeTab, currentActiveTab } = useLayoutTabsState(true);
  const { showAllFiltersPopup } = useAllFiltersPopup(
    'village',
    buildingFilters.filters,
    buildingFilters.filters.reset
  );

  useEffect(() => {
    villageStore.fetchVillageDetails(villageId);
  }, [villageId]);

  useEffect(() => {
    const resolvedSectionId = selectedSectionId
      ? selectedSectionId.toString()
      : 'defaultSectionId';

    const parentRoute = appRoutesService.replaceRoute('generalPlan', {
      complexId,
      generalPlanId,
      viewType: 'map',
      sectionId: resolvedSectionId,
    });
    authRoutesStore.setParentRoute(parentRoute);

    if (!user) {
      const newPath = authRoutesStore.getRouteWithParent('authPhoneCodePage');
      history.replace(newPath);
    } else if (!viewType || viewType === ':viewType') {
      const firstNonEmptySection = sections.find((section) => section?.stats?.apartments > 0);
      const newPath = appRoutesService.replaceRoute('generalPlan', {
        complexId,
        generalPlanId,
        viewType: 'gen',
        sectionId: firstNonEmptySection ? firstNonEmptySection.id.toString() : String(selectedSectionId),
      });
      history.replace(newPath);
    } else if (cottageId && cottageNumber) {
      setCurrentActiveTabNew(viewType === 'list' ? 1 : 0);
      const newPath = appRoutesService.replaceRoute('generalPlanCottage', {
        complexId,
        generalPlanId,
        viewType: viewType,
        sectionId: String(selectedSectionId),
        cottageId: cottageId,
        cottageNumber: cottageNumber,
      });
      history.replace(newPath);
    } else {
      setCurrentActiveTabNew(viewType === 'list' ? 1 : 0);
      const newPath = appRoutesService.replaceRoute('generalPlan', {
        complexId,
        generalPlanId,
        viewType: viewType,
        sectionId: String(selectedSectionId),
      });
      history.replace(newPath);
    }
  }, [viewType, complexId, generalPlanId, history, selectedSectionId, cottageNumber]);

  const changeTabNew = (number: number) => {
    setCurrentActiveTabNew(number);
    const newViewType = number === 1 ? 'list' : 'map';
    const newPath = appRoutesService.replaceRoute('generalPlan', {
      complexId,
      generalPlanId,
      viewType: newViewType,
      sectionId: String(selectedSectionId),
    });
    history.push(newPath);
  };

  if (villageStore.loadingVillage) {
    return <div>Загрузка...</div>;
  }

  if (!villageStore.villageDetails) {
    return <div>Данные о поселке не найдены</div>;
  }

  return (
    <>
      <MediaQuery mediaQuery="(min-width: 1200px)">
        <LayoutPageHeaderDesktop
          headerProps={{
            currentActiveTab,
            tabList,
            changeTab,
            showAllFiltersPopup,
            changeTabNew,
            currentActiveTabNew,
          }}
          isVillage={true}
        />
      </MediaQuery>
        {sections.length === 0 ? (
          <div className={cc([styles.village])}>
            <NoResults />
          </div>
          ) : currentActiveTabNew === 1 ? (
            <div className={cc([styles.village])}>
              <GeneralPlanTable
                villageId={villageId} sections={sections}
                selectedSectionId={selectedSectionId}
                setSelectedSectionId={setSelectedSectionId}
                filtersForAPI={filtersForAPI}
              />
            </div>
          ) : (
          <div className={cc([styles.villageFont])}>
            <GeneralPlanMap
              villageId={villageId}
              sections={sections}
              selectedSectionId={selectedSectionId}
              setSelectedSectionId={setSelectedSectionId}
              filtersForAPI={filtersForAPI}
            />
          </div>
        )}
      <AuthPage
        components={{
          authPhoneCodePage: () => <AuthForGetAccessToVillage />,
          authEmailCodePage: () => <AuthForGetAccessToVillage />,
        }}
        parentRoute={appRoutesService.getRoute('generalPlan')}
      />
    </>
  );
});



