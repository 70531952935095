import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ChoicerUserCity } from 'components/containers/ChoicerUserCity';

import { appRoutesService } from 'services';
import eselloLogoLight from 'assets/eselloLogoLight.svg';
import eselloLogoDark from 'assets/eselloLogoDark.svg';
import { FeatureToggle } from 'components/atoms';
import { NavbarRightContent } from './components/NavbarRightContent';

import { NavList } from './components/NavList';
import styles from './styles.module.css';
import {useTheme} from "../../../../../context/theme/useTheme";

export const NavbarDesktopRender = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <header className={styles.desktopWrapper}>
      <div className={styles.leftContent}>
        <Link to={appRoutesService.getRoute('main')} className={styles.logo}>
          <img src={theme === 'dark-theme' ? eselloLogoDark : eselloLogoLight} alt="site logo"/>
        </Link>

        <ChoicerUserCity/>
        {/*<FeatureToggle hideForProduction>*/}
        {/*  <NavList className={styles.navListWrapper}/>*/}
        {/*</FeatureToggle>*/}
      </div>

      <div className={styles.rightContent}>
        <NavbarRightContent/>
      </div>
    </header>
  )
};

export const NavbarDesktop = observer(NavbarDesktopRender);
