import React from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { TabsSelect } from 'components/containers/TabsSelect';
import { TabList } from "../TabList";
import { TabButton } from "../TabButton";

type Props = {
  count: number;
  maxLevel: number;
  setCount: (count: number) => void;
};

export const FloorPopupsNavBarCottage = ({ count, maxLevel, setCount }: Props) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const getTabIndex = (count: number) => count - 1;
  const getPlanIndex = (index: number) => index + 1;

  const tabs = new Array(maxLevel).fill(0).map((_, index) => ({
    label: `${getPlanIndex(index)} уровень`,
  }));

  const currentActiveTab = getTabIndex(count);

  return (
    <>
      {!isLarge && (
        <TabsSelect
          changeTab={(index) => () => {
            setCount(getPlanIndex(index));
          }}
          currentActiveTab={currentActiveTab}
          tabList={tabs}
        />
      )}

      {isLarge && (
        <TabList currentActiveTab={currentActiveTab} tabCount={tabs.length}>
          {tabs.map(({ label }, index) => (
            <TabButton
              onClick={() => {
                setCount(getPlanIndex(index));
              }}
              key={label}
              label={label}
              isActive={currentActiveTab === index}
            />
          ))}
        </TabList>
      )}
    </>
  );
};
