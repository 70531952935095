import React from 'react';
import ReactModal from 'react-modal';
import { SalesDepartment } from '../../../../../../services/v1/rc/viewVillage/types';
import { ButtonBase } from '../../../../components/ButtonBase';
import styles from './ContactModal.module.scss';
import closeIconLight from '../../../../../../assets/closeIconLight.svg';
import closeIconDark from '../../../../../../assets/closeIconDark.svg';
import adressIcon from '../../assets/contacts/adress-icon.svg';
import emailIcon from '../../assets/contacts/email-icon.svg';
import phoneIcon from '../../assets/contacts/phone-icon.svg';
import copyIcon from '../../assets/contacts/copy-icon.svg';
import defaultUserImage from '../../assets/contacts/default-user-icon.svg';
import { useTheme } from '../../../../../../context/theme/useTheme';
import cc from 'classcat';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  salesDepartments: SalesDepartment;
};

export const ContactModal: React.FC<Props> = ({ isOpen, onClose, salesDepartments }) => {
  const contacts = salesDepartments?.contacts || [];
  const { theme } = useTheme();

  const daysOfWeek = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
  ];

  // Функция для форматирования времени из секунд в формат HH:MM
  const formatTime = (seconds: number) => {
    const date = new Date(seconds * 1000);
    const hours = date.getUTCHours().toString().padStart(2, '0');
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      className={cc([styles.modalContent, theme])}
      overlayClassName={styles.modalOverlay}
    >
      <ButtonBase onClick={onClose} className={styles.closeButton}>
        <img
          src={theme === 'dark-theme' ? closeIconLight : closeIconDark}
          className={styles.closeButtonIcon}
          alt="close modal"
        />
      </ButtonBase>
      <div className={styles.contactList}>
        <div className={styles.companyInfoBlock}>
          {salesDepartments?.company?.logo?.url && (
            <div className={styles.logoWrapper}>
              <img
                src={salesDepartments.company.logo.url}
                alt="Логотип компании"
                className={styles.logo}
              />
            </div>
          )}
          <div className={styles.companyInfo}>
            <div className={styles.companyName}>{salesDepartments?.company?.title}</div>
            <div className={styles.companyType}>Застройщик</div>
          </div>
        </div>

        <div className={styles.companyContacts}>
          <h3 className={styles.companyTitle}>{salesDepartments?.title}</h3>
          {
            salesDepartments?.company?.description && (
              <p className={styles.companyDescription}>salesDepartments?.company?.description</p>
            )
          }
          <p className={styles.companyDescription}>Номер для связи в Telegram +7 908 192 70 00</p>
          <div className={styles.companyContact}>
            <div className={styles.companyContactImg}>
              <img src={adressIcon} alt=""/>
            </div>
            <span>
              {salesDepartments?.address_original || salesDepartments?.company?.address}
            </span>
          </div>
          {salesDepartments?.phone && (
            <div className={styles.companyContact}>
              <div className={styles.companyContactImg}>
                <img src={phoneIcon} alt=""/>
              </div>
              <span>{salesDepartments?.phone}</span>
            </div>
          )}
          {salesDepartments?.email && (
            <div className={styles.companyContact}>
              <div className={styles.companyContactImg}>
                <img src={emailIcon} alt=""/>
              </div>
              <span>{salesDepartments?.email}</span>
            </div>
          )}
        </div>

        <h3 className={styles.companyTitle}>График работы</h3>
        <div className={styles.companySchedule}>
          {salesDepartments?.work_times && salesDepartments?.work_times?.length > 0 && salesDepartments?.work_times?.map((workTime, index) => (
            <div key={workTime?.day} className={styles.companyScheduleText}>
              <span>{daysOfWeek[index]}</span>
              <span>
                {workTime?.isActive
                  ? `${formatTime(workTime?.startTime)} - ${formatTime(workTime?.endTime)}`
                  : 'Выходной'}
              </span>
            </div>
          ))}
        </div>

        <div className={styles.companyUsers}>
          {contacts.map((contact) => (
            <div key={contact.id} className={styles.companyUser}>
              <div className={styles.companyUserImg}>
                <img
                  src={contact.logo?.url || defaultUserImage}
                  alt={contact?.fio ? contact?.fio : ''}
                />
              </div>
              <div>
                <h4 className={styles.companyUserName}>{contact.fio}</h4>
                <span className={styles.companyUserPosition}>{contact.rank}</span>
                <p className={styles.companyUserExperience}>{contact.description}</p>
                {contact.phone && (
                  <div className={styles.companyContact}>
                    <div className={styles.companyContactImg}>
                      <img src={phoneIcon} alt="" />
                    </div>
                    <span>{contact.phone}</span>
                    <div>
                      <img src={copyIcon} alt="copy" />
                    </div>
                  </div>
                )}
                {contact.email && (
                  <div className={styles.companyContact}>
                    <div className={styles.companyContactImg}>
                      <img src={emailIcon} alt="" />
                    </div>
                    <span>{contact.email}</span>
                    <div>
                      <img src={copyIcon} alt="copy" />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </ReactModal>
  );
};
