import React from 'react';

type Props = {
  isActive: boolean;
}

const GenplanIcon = ({isActive}: Props) => {
  return (
    isActive ? (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.5 4C15.5 3.8 15.4 3.65 15.25 3.55L11.75 1.55C11.65 1.5 11.6 1.5 11.5 1.5C11.4 1.5 11.35 1.5 11.25 1.55L8 3.4L4.75 1.55C4.65 1.5 4.6 1.5 4.5 1.5C4.4 1.5 4.35 1.5 4.25 1.55L0.75 3.55C0.6 3.65 0.5 3.8 0.5 4V14C0.5 14.3 0.7 14.5 1 14.5C1.1 14.5 1.15 14.45 1.25 14.45L4.5 12.6L7.75 14.45C7.85 14.5 7.9 14.5 8 14.5C8.1 14.5 8.15 14.5 8.25 14.45L11.5 12.6L14.75 14.45C14.8 14.5 14.9 14.5 15 14.5C15.3 14.5 15.5 14.3 15.5 14V4ZM1.5 4.3L4 2.85V11.7L1.5 13.15V4.3ZM7.5 13.15L5 11.7V2.85L7.5 4.3V13.15ZM11 11.7L8.5 13.15V4.3L11 2.85V11.7ZM12 11.7V2.85L14.5 4.3V13.15L12 11.7Z"
            fill="#4E6AFF"/>
        </svg>
      ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.5 4C15.5 3.8 15.4 3.65 15.25 3.55L11.75 1.55C11.65 1.5 11.6 1.5 11.5 1.5C11.4 1.5 11.35 1.5 11.25 1.55L8 3.4L4.75 1.55C4.65 1.5 4.6 1.5 4.5 1.5C4.4 1.5 4.35 1.5 4.25 1.55L0.75 3.55C0.6 3.65 0.5 3.8 0.5 4V14C0.5 14.3 0.7 14.5 1 14.5C1.1 14.5 1.15 14.45 1.25 14.45L4.5 12.6L7.75 14.45C7.85 14.5 7.9 14.5 8 14.5C8.1 14.5 8.15 14.5 8.25 14.45L11.5 12.6L14.75 14.45C14.8 14.5 14.9 14.5 15 14.5C15.3 14.5 15.5 14.3 15.5 14V4ZM1.5 4.3L4 2.85V11.7L1.5 13.15V4.3ZM7.5 13.15L5 11.7V2.85L7.5 4.3V13.15ZM11 11.7L8.5 13.15V4.3L11 2.85V11.7ZM12 11.7V2.85L14.5 4.3V13.15L12 11.7Z"
          fill="#333338"/>
      </svg>
    )
  );
};

export default GenplanIcon;
