import React, { useRef, useEffect, useState } from 'react';
import styles from './PlanToggle.module.scss';
import cc from "classcat";

interface SectionType {
  id: number;
  title: string;
  residential_complex_id: number;
  stats: {
    apartments: number;
  };
}

interface PlanToggleProps {
  sections: SectionType[];
  selectedSectionId: number | null;
  onSelectSection: (sectionId: number) => void;
  sectionCounts: { [key: number]: number };
  className?: string;
}

export const PlanToggle: React.FC<PlanToggleProps> = ({
  sections,
  selectedSectionId,
  onSelectSection,
  sectionCounts,
  className,
}) => {
  const [indicatorStyle, setIndicatorStyle] = useState({ width: 0, left: 0 });
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);

  useEffect(() => {
    // Update the indicator's position and width when the selectedSectionId changes
    const selectedButton = buttonRefs.current[sections.findIndex((s) => s.id === selectedSectionId)];
    if (selectedButton) {
      setIndicatorStyle({
        width: selectedButton.offsetWidth,
        left: selectedButton.offsetLeft,
      });
    }
  }, [selectedSectionId, sections]);

  // Function to get the correct word for "objects"
  const getObjectWord = (count: number) => {
    count = Math.abs(count) % 100;
    const n = count % 10;

    if (count > 10 && count < 20) return 'объектов';
    if (n > 1 && n < 5) return 'объекта';
    if (n === 1) return 'объект';
    return 'объектов';
  };

  return (
    <div className={cc([styles.planToggleContainer, className])}>
      <div className={styles.planToggle}>
        {sections.map((section, index) => (
          <div key={section.id}>
            <button
              ref={(el) => (buttonRefs.current[index] = el)} // Set ref for each button
              className={`${selectedSectionId === section.id ? styles.active : ''}`}
              onClick={() => onSelectSection(section.id)}
            >
              {`${index + 1} корпус`}
              <span className={styles.objectCount}>
                {sectionCounts[section.id] || 0} {getObjectWord(sectionCounts[section.id] || 0)}
              </span>
            </button>
          </div>
        ))}
        <div
          className={styles.activeIndicator}
          style={{
            width: indicatorStyle.width,
            left: indicatorStyle.left,
          }}
        />
      </div>
    </div>
  );
};



