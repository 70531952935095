import React, { ReactNode } from 'react';
import { Field, useField } from 'formik';
import cc from 'classcat';
import plusIcon from 'assets/plus.svg';
import closeIcon from 'assets/closeRed.svg';
import { FieldArrayRenderProps } from 'formik/dist/FieldArray';
import { Icon, FormFieldLabel } from 'components/atoms';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import styles from './styles.module.css';

type Props = FieldArrayRenderProps & {
  label?: string;
  className?: string;
  /**
   * @desc значение по умолчанию которое будет пушится при добавлении новых
   * элементов в список
   * */
  defaultValue?: any;
  /**
   * @desc компонент элемента списка, по умолчанию компонент из formik
   * */
  component?: ReactNode;
  /**
   * @desc пропсы для компонента элемента списка
   * */
  componentProps?: any;
};

/**
 * @desc компонент для отрисовки списка элементов в форме,
 * ВАЖНО! в компоненте нет инициализации по умолчанию первого элемента списка,
 * по этому в форме необходимо установить значение по умолчанию.
 * Так же из-за текущего дизайна, сделан запрет на полное очищение списка,
 * в списке должен быть хотя бы один элемент.
 * */
export const FormikFieldArray = ({
  className,
  push,
  remove,
  defaultValue,
  name,
  component,
  componentProps,
  label,
}: Props) => {
  const [field] = useField(name);

  const showButtonAdd = (value: undefined | any[]) => (index: number) => {
    if (Array.isArray(value)) {
      return index === 0;
    }

    return true;
  };

  return (
    <div className={cc([className])}>
      {label && (
        <FormFieldLabel fieldName={field?.name}>{label}</FormFieldLabel>
      )}
      <ul className={styles.fieldList}>
        {Array.isArray(field.value) &&
          field.value.map((value, index) => (
            <li key={index.toString()} className={styles.fieldItem}>
              <Field
                component={component}
                name={`${field.name}[${index}]`}
                {...componentProps}
              />
              {!showButtonAdd(field.value)(index) && (
                <ButtonBase
                  icon={<Icon src={closeIcon} />}
                  onClick={() => {
                    if (field.value && field.value.length > 1) {
                      remove(index);
                    }
                  }}
                />
              )}
              {showButtonAdd(field.value)(index) && (
                <ButtonBase
                  icon={<Icon src={plusIcon} />}
                  onClick={() => {
                    push(defaultValue);
                  }}
                />
              )}
            </li>
          ))}
      </ul>
    </div>
  );
};
