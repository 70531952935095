import React from 'react';
import styles from './styles.module.css';
import cc from "classcat";

type Props = {
  activeIndex: number;
  photosLength: number;
  isCottage?: boolean;
};

export const AlbumPhotoCounter = ({ photosLength, activeIndex, isCottage }: Props) => (
  <span className={cc([styles.photoCount, isCottage && styles.photoCountCottage])}>
    {`Фото ${activeIndex + 1} из ${photosLength}`}
  </span>
);
