import React from 'react';
import { appRoutesService, ComplexAlbum } from 'services';
import { LocationDescriptorObject } from 'history';
import { AlbumItem } from '../AlbumItem';
import styles from './styles.module.css';

type Props = {
  albums: ComplexAlbum[];
  complexId: string;
  to?: LocationDescriptorObject<any>;
  isCottage?: boolean;
};

export const AlbumList = ({ albums, complexId, isCottage = false, to }: Props) => {
  return (
    <ul className={styles.albumsList}>
      {albums.map((album) => (
        <AlbumItem
          to={appRoutesService.replaceRoute(
            isCottage ? 'cottageAlbums' : 'albums',
            isCottage
              ? { cottageId: complexId, albumId: album.id.toString() }
              : { complexId: complexId, albumId: album.id.toString() },
          )}
          key={album.id}
          {...album}
        />
      ))}
    </ul>
  )
};
