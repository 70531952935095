import React, { ReactNode, CSSProperties, useState } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  filterKey: string;
  store: any;
  placeholder?: string;
  prefixText?: string;
  suffixIcon?: ReactNode;
  clearable?: boolean;
  className?: string;
  disabled?: boolean;
  customStyles?: CSSProperties;
  onChange?: (value: string | number) => void;
};

export const ReactiveInputRender = ({
  filterKey,
  store,
  placeholder = '',
  prefixText,
  suffixIcon,
  clearable = false,
  className,
  disabled,
  customStyles,
  onChange,
}: Props) => {
  const [inputValue, setInputValue] = useState(
    store.filters[filterKey] || '',
  );

  const formatValue = (value: string | number) =>
    value
      ? String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      : '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s/g, ''); // Убираем пробелы
    if (!isNaN(Number(value))) {
      setInputValue(value);
      store.setField(filterKey, Number(value));
      onChange?.(Number(value));
    }
  };

  const handleClear = () => {
    setInputValue('');
    store.setField(filterKey, undefined);
    onChange?.('');
  };

  return (
    <div className={cc([className, styles.inputWrapper])}>
      {prefixText && <span className={styles.prefixText}>{prefixText}</span>}
      <input
        type="text"
        placeholder={placeholder}
        value={formatValue(inputValue)}
        onChange={handleChange}
        disabled={disabled}
        className={cc([
          styles.inputTag,
          { [styles.inputTagWithSuffix]: suffixIcon || clearable },
        ])}
        style={customStyles}
      />
      {suffixIcon && <span className={cc([styles.suffixIcon, inputValue && styles.suffixIconClose])}>{suffixIcon}</span>}
      {clearable && inputValue && (
        <button
          type="button"
          className={styles.clearButton}
          onClick={handleClear}
        >
          ✕
        </button>
      )}
    </div>
  );
};

export const ReactiveInput = observer(ReactiveInputRender);

