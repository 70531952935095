import React, {memo, useState} from 'react';
import { useParams } from 'react-router-dom';
import { buildingFilters } from 'stores';
import { observer } from 'mobx-react';
import { Params } from 'services';
import cc from 'classcat';
import { BaseLink } from 'components/atoms';
import { TabsView } from 'components/containers/Tabs';
import arrowLeft from 'assets/darkArrowLeft.svg';
import { LayoutFilters } from '../LayoutFilters';
import { useLayoutTabsState } from '../../hooks/useLayoutTabsState';
import { ApartmentViewFilterWithShowCondition } from '../ApartmentViewFilterWithShowCondition';
import styles from './styles.module.css';
import { TabButton } from "../../pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabButton";
import { TabList } from "../../pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabList";
import GenplanIcon from "../../../../components/ui/GenplanIcon";
import GenplanListIcon from "../../../../components/ui/GenplanListIcon";
import ArrowLeftIcon from "../../../../components/ui/ArrowLeftIcon";

type Props = {
  showAllFiltersPopup: () => void;
  isVillage?: boolean;
  changeTabNew: (number: number) => void;
  currentActiveTabNew?: number;
} & Pick<ReturnType<typeof useLayoutTabsState>, 'currentActiveTab'> &
  Pick<ReturnType<typeof useLayoutTabsState>, 'tabList'> &
  Pick<ReturnType<typeof useLayoutTabsState>, 'changeTab'>;

const LayoutPageHeaderRight = {
  tabsView: 'LayoutPageHeaderRight',
};

export const HeaderRightRender = ({
  currentActiveTab,
  tabList,
  changeTab,
  showAllFiltersPopup,
  isVillage = false,
  changeTabNew,
  currentActiveTabNew,
}: Props) => {
  const { complexId } = useParams<Pick<Params, 'complexId'>>();

  return (
    <div className={cc([styles.wrapper, isVillage && styles.wrapperVillage])}>
      {!isVillage && <ApartmentViewFilterWithShowCondition/>}

      <div className={isVillage ? styles.headerRightFilter : ''}>
        <LayoutFilters
          onFilterOpen={showAllFiltersPopup}
          onFilterReset={buildingFilters.filters.reset}
          resultedFilters={buildingFilters.filters.filterToApiFormat}
        />
      </div>

      {isVillage && (
        <TabList currentActiveTab={currentActiveTabNew} tabCount={2}>
          <TabButton
            label="Генплан"
            onClick={() => changeTabNew(0)}
            isActive={currentActiveTabNew === 0}
            tabIcon={<GenplanIcon isActive={currentActiveTabNew === 0} />}
          />
          <TabButton
            label="Список"
            onClick={() => changeTabNew(1)}
            isActive={currentActiveTabNew === 1}
            tabIcon={<GenplanListIcon isActive={currentActiveTabNew === 1} />}
          />
        </TabList>
      )}

      {
        !isVillage && (
          <TabsView
            testId={LayoutPageHeaderRight.tabsView}
            tabList={tabList}
            changeTab={changeTab}
            currentActiveTab={currentActiveTab}
          />
        )
      }

      <BaseLink
        path="view"
        params={{
          complexId,
        }}
        icon={isVillage ? <ArrowLeftIcon /> : <img style={{width: '16px'}} src={arrowLeft} alt=""/>}
        className={isVillage ? styles.headerRightButton : ''}
      >
        {isVillage ? 'На страницу КП' : 'На страницу ЖК'}
      </BaseLink>
    </div>
  );
};

export const HeaderRight = memo(observer(HeaderRightRender));
