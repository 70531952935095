import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import cc from "classcat";
import SwiperType from "swiper/types/swiper-class";
import { AlbumPhotoToFilter } from "services";
import mainLogo from "assets/eselloLogo.svg";
import styles from "../../styles.module.css";

type Props = {
  slidesPerView: number;
  photosToShow: AlbumPhotoToFilter[];
  activeIndex: number;
  onSwiper?: (swiper: SwiperType) => void;
  isCottage?: boolean;
};

export const PhotosNavigationSwiper = ({
  slidesPerView,
  photosToShow,
  activeIndex,
  onSwiper,
  isCottage = false,
}: Props) => {
  // Фильтруем только слайды с изображением или логотипом
  const filteredPhotos = photosToShow.filter(({ url, logo }) => url || logo);

  return (
    <Swiper
      className={cc([styles.thumbsSwiper, isCottage && styles.thumbsSwiperCottage])}
      spaceBetween={10}
      slidesPerView={slidesPerView}
      virtualTranslate
      lazy
      id="swiper-thumbnails"
      onSwiper={onSwiper}
    >
      {filteredPhotos.map(({ url, logo }, index) => (
        <SwiperSlide
          className={cc([
            styles.thumbsSwiperSlide,
            index === activeIndex && styles.thumbsSwiperSlideActive,
          ])}
          key={url}
        >
          <img
            src={logo || mainLogo}
            alt={url}
            className={styles.miniThumbnail}
            loading="lazy"
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
