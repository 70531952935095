import React, { ReactNode } from 'react';
import cc from "classcat";
import ReactModal from 'react-modal';
import { useMediaQuery } from 'beautiful-react-hooks';
import Simplebar from 'simplebar-react';

import { ApartmentDto } from 'services';
import hideRightSidebar from 'assets/hideRightSidebarNew.svg';
import hideRightDarkSidebar from 'assets/hideRightDarkSidebar.svg';
import { ButtonBase } from '../../../../../components/ButtonBase';
import {useTheme} from "../../../../../../../context/theme/useTheme";
import { ApartmentViewMobilePopup } from "../../../../ApartmentView/components/ApartmentViewMobilePopup";
import styles from './CottageViewPopup.module.scss';

type Props = {
  onClose?: () => void;
  isOpen: boolean;
  children?: ReactNode;
} & Pick<ApartmentDto, 'number'>;

export const CottageViewPopup = ({
  onClose,
  children,
  isOpen,
  number,
}: Props) => {
  const isLarge = useMediaQuery('(min-width: 768px)');

  const { theme } = useTheme();

  if (isLarge) {
    return (
      <ReactModal
        shouldCloseOnEsc
        ariaHideApp={false}
        isOpen={isOpen}
        overlayClassName={styles.modalOverlay}
        className={cc([styles.modalContent, theme])}
      >
        <ButtonBase
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
          className={styles.closeButton}
          icon={<img src={theme === 'dark-theme' ? hideRightDarkSidebar : hideRightSidebar} alt="close" />}
          color='transparent'
        />
        <Simplebar className={styles.simplebar}>{children}</Simplebar>
      </ReactModal>
    );
  }

  // временная мобильная версия
  return (
    <ApartmentViewMobilePopup
      isOpen={isOpen}
      number={number}
      onClose={() => onClose && onClose()}
      isCottage={true}
    >
      {children}
    </ApartmentViewMobilePopup>
  );
};
