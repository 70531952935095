import React from 'react';
import { observer } from 'mobx-react';
import { BuildingFiltersStore, complexFilterStore } from 'stores';
import { AllFilters } from '../AllFilters';
import { FilterBottomButtons } from './components/FilterBottomButtons';
import { FilterHeader } from './components/FilterHeader';
import { TOpenType } from 'types/filters';
import { ContextValueVillageFilters } from 'context';
import { useTheme } from "../../../context/theme/useTheme";
import cc from "classcat";
import styles from './styles.module.scss';

type Props = {
  onClose: () => void;
  openType?: TOpenType;
  store?: BuildingFiltersStore['filters'] | {filters: ContextValueVillageFilters['villageDataFilter']},
  handleChangeStore?: ContextValueVillageFilters["handleChangeVillageFilter"],
  handleResetFilter?: ContextValueVillageFilters["handleResetVillageFilter"] | any
  isCottage?: boolean;
};

export const AllFiltersPopup = observer(({ onClose, openType, store, handleChangeStore, handleResetFilter, isCottage }: Props) => {
  const { theme } = useTheme();

  return <div className={cc([styles.wrapper, theme])}>
    <section className={styles.filtersWrapper}>
      <FilterHeader onClose={onClose} openType={openType} />
      <AllFilters
        openType={openType}
        store={store}
        handleChangeStore={handleChangeStore}
      />
    </section>

    <FilterBottomButtons
      onClose={onClose}
      onCleanFilter={handleResetFilter || complexFilterStore.filters.reset}
      isCottage={isCottage}
    />
  </div>
});
