import React from 'react';
import SimpleBar from 'simplebar-react';
import { TruncateHtml } from 'components/atoms';
import styles from './styles.module.css';

type Props = {
  name: string;
  description: string;
  isCottage?: boolean;
};

export const AlbumDescription = ({ description, name, isCottage = false }: Props) => (
  <SimpleBar className={isCottage ? styles.swiperDescriptionCottage : styles.swiperDescription}>
    {
      !isCottage && <h3>{name}</h3>
    }
    <TruncateHtml atOneLine text={description} />
  </SimpleBar>
);
