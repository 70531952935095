import { Option } from 'components/atoms/Form/FormikSelect/types';

export const TEST_RECAPTCHA_TOKEN = 'TEST_RECAPTCHA_TOKEN';

export const CONTENT_DOES_NOT_EXIST = '—';

export const FORM_SUBMISSION_ERROR = 'FORM_SUBMISSION_ERROR';

export const OTHER = 'other';

export const OTHER_OPTION = {
  label: 'Другое',
  value: OTHER,
};

export const USER_ROLES: Option[] = [
  {
    label: 'Риэлтор',
    value: 'realtor',
  },
  {
    label: 'Застройщик',
    value: 'developer',
  },
];

export const API_ROUTES = {
  search: 'v1/rc/search',
  view: '/v1/rc/view/:id',
  viewVillage: '/v1/village/view/:id',

  info: 'v1/info',
  pdf: '/v1/apartment',

  cities: '/v1/cities',
  city: '/v1/city/:cityId',
  districts: '/v1/city/:cityId/districts',

  feedback: '/v1/feedback',
  stock: '/v1/rc/stock/:stockId',

  albums: '/v1/rc/:complexId/albums',
  cottageAlbums: '/v1/village/cottage/:cottageId/albums',
  album: '/v1/rc/album/:albumId',
  cottageAlbum: '/v1/village/cottage/albums/:albumId',
  building: '/v1/rc/building/:buildingId',
  apartmentsList: '/v1/apartments',
  apartment: '/v1/apartment/:apartmentId',
  suggest: '/v1/suggest',

  developers: '/v1/developers',
  developer: '/v1/developer/:developerId',

  agencies: '/v1/agencies',
  agency: '/v1/agency/:agencyId',

  village: {
    view: '/v1/village/view/:villageId',
    cottages: '/v1/village/cottages',
    cottage: '/v1/village/cottage/:apartmentId',
  },

  user: {
    profile: '/v1/user/profile',
    profilePhoto: '/v1/user/upload/photo',
    feed: '/v1/user/feed/list',
  },

  favorites: {
    list: '/v1/user/favorites',
    listIds: '/v1/user/favorites/list',
    toggle: '/v1/user/favorites/toggle',
    sync: '/v1/user/favorites/sync',
  },

  auth: {
    phone: {
      code: '/v1/auth/phone/code',
      validate: '/v1/auth/phone/validate',
      registration: '/v1/auth/phone/registration',
      change: {
        change: '/v1/auth/phone/change',
        confirm: '/v1/auth/phone/change/confirm',
      },
    },
    email: {
      code: '/v1/auth/email/code',
      validate: '/v1/auth/email/validate',
      confirm: {
        confirm: '/v1/auth/email/confirm',
        repeat: '/v1/auth/email/confirm/repeat',
      },
      change: {
        change: '/v1/auth/email/change',
        confirm: '/v1/auth/email/change/confirm',
      },
    },
    logout: '/v1/auth/logout',
  },
  feed: {
    download: '/v1/feed/download/:hash',
  },
  offers: {
    list: '/v1/offers/list',
    view: {
      viewByOfferId: '/v1/offers/view/:offerId',
      viewByHash: '/v1/offers/view/hash/:hash',
    },
    create: '/v1/offers/create',
    edit: {
      edit: '/v1/offers/edit',
      view: '/v1/offers/edit/view',
      note: '/v1/offers/edit/note',
      status: '/v1/offers/edit/status',
    },
    favorite: '/v1/offers/favorite',
    apartment: {
      apartment: '/v1/offers/apartment/:apartmentId',
      add: '/v1/offers/apartment/:apartmentId/add',
      status: '/v1/offers/apartment/:apartmentId/status',
    },
  },
};
// @todo: удалить
export const unsetted = 'Не указано';

export const FILES_API_ROUTES = {
  uploads: 'uploads',
};
