import React, { useEffect } from 'react';
import {Route, Switch, Router} from 'react-router';

import Main from 'pages/Main';
import { AlbumPage } from 'pages/AlbumPage';
import { OfferApartmentPage } from 'pages/OfferApartmentPage';
import { ProfilePage } from 'pages/ProfilePage';
import { ModalProvider } from 'react-modal-hook';
import { FiltersProvider } from 'contexts/filtersContext';

import { useHistory } from 'hooks/useHistory';
import { appRoutesService } from 'services';
import { useCalcVhUnit } from 'hooks';
import { LayoutPage } from 'pages/LayoutPage';
import { OffersViewPage } from 'pages/OffersViewPage';
import { OffersEditorPage } from 'pages/ProfilePage/pages/OffersEditorPage';
import { AddToOffersPage } from 'pages/ProfilePage/pages/AddToOffersPage/AddToOffersPage';
import { ModalRoute } from './components/atoms';
import { NavbarMainPage } from 'pages/Main/containers/Navbar/NavbarMainPage';
import { GeneralPlanVillage } from 'pages/LayoutPage/pages/GeneralPlanVillage';
import { useIframeMode } from 'hooks/useIframeMode';
import { PdfPage } from "pages/PdfPage";
import { useTheme } from "./context/theme/useTheme";

export const App = () => {
  const { theme } = useTheme();
  const history = useHistory();
  const isIframeMode = useIframeMode();
  useCalcVhUnit();

  const isPdfPage = history.location.pathname.includes('/pdf');

  useEffect(() => {
    if (!isPdfPage) {
      const script = document.createElement('script');
      script.src = "//code.jivo.ru/widget/PYwQDceley";
      script.async = true;
      document.body.appendChild(script);

      return () => {};
    }
  }, [isPdfPage]);

  return (
    <FiltersProvider>
      <ModalProvider>
        <div className={theme}>
          <Router history={history}>
            {!isIframeMode && !isPdfPage && <div className='mainNavbar'>
              <NavbarMainPage/>
            </div>}
            <Switch>
              <Route
                path={appRoutesService.getRoute('pdfPage')}
                exact
                component={PdfPage}
              />
              <Route
                path={appRoutesService.getRoute('albums')}
                exact
                component={AlbumPage}
              />
              <Route
                path={appRoutesService.getRoute('cottageAlbums')}
                exact
                component={AlbumPage}
              />
              <Route
                path={appRoutesService.getRoute('myModal')}
                exact
                component={OfferApartmentPage}
              />

              <Route
                path={appRoutesService.getRoute('profile')}
                component={ProfilePage}
              />

              <Route
                path={appRoutesService.getRoute('offersApartmentView')}
                component={OfferApartmentPage}
              />

              <Route
                path={appRoutesService.getRoute('offersViewClient')}
                component={OffersViewPage}
              />
              <Route
                path={appRoutesService.getRoute('layout')}
                exact
                component={LayoutPage}
              />
              <Route
                path={appRoutesService.getRoute('layoutActiveApartment')}
                exact
                component={LayoutPage}
              />
              <Route
                path={appRoutesService.getRoute('layoutActiveTab')}
                exact
                component={LayoutPage}
              />
              <Route
                path={appRoutesService.getRoute('layoutActiveTabAuth')}
                component=
                  {LayoutPage}
              />
              <Route
                path={appRoutesService.getRoute('generalPlanCottage')}
                component={GeneralPlanVillage}
              />
              <Route
                path={appRoutesService.getRoute('generalPlan')}
                component={GeneralPlanVillage}
              />
              <Route path={appRoutesService.getRoute('main')} component={Main}/>
            </Switch>

            <ModalRoute component={AddToOffersPage} path="addToOffer"/>
            <ModalRoute component={OffersEditorPage} path="offersCreate"/>
            <ModalRoute component={OffersEditorPage} path="offersEdit"/>
          </Router>
        </div>
      </ModalProvider>
    </FiltersProvider>
  );
};
