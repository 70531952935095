import React from 'react';
import { BuildingFiltersStore } from 'stores';
import { FirstColumnFilter } from '../Search/components/FirstColumnFilters';
import styles from './AllFilters.module.scss';

type Props = {
  isShowFromComplex?: boolean;
  store?: BuildingFiltersStore['filters'];
}

export const ComplexTab = ({isShowFromComplex = false, store }: Props) => (
  <>
    <div className={styles.formColumn}>
      <FirstColumnFilter isShowFromComplex={isShowFromComplex} store={store} />
    </div>

    {/*<div className={styles.formColumn}>*/}
    {/*  <SecondColumnFilter isShowFromComplex={isShowFromComplex} store={store}/>*/}
    {/*</div>*/}

    {/*<div className={styles.formColumn}>*/}
    {/*  <ThirdColumnFilters isShowFromComplex={isShowFromComplex} store={store}/>*/}
    {/*</div>*/}
  </>
);
