import { ReactElement } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';

type Props = {
  children: ReactElement;
};

export const DesktopMediaQuery = ({ children }: Props) => {
  const isShow = useMediaQuery('(min-width: 1024px)');
  if (!isShow) {
    return null;
  }
  return children;
};
