import React, { ComponentProps, useState } from 'react';
import { observer } from 'mobx-react';
import cc from "classcat";
import { addToOffersStore } from 'stores/OffersStore/AddToOffersStore';
import { Checkbox } from 'components/atoms/Checkbox';
import { OffersCard } from '../OffersCard';
import { Alert } from 'components/atoms/Alert';
import addedIcon from 'assets/addedIcon.svg';
import removedIcon from 'assets/removedIcon.svg';
import styles from "./styles.module.css";

type Props = ComponentProps<typeof OffersCard>;

export const SelectableOffersCardRender = ({ offer }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertIcon, setAlertIcon] = useState<string | null>(null);

  const isChecked = addToOffersStore.checkedOffers.includes(offer.id);

  const handleChange = async () => {
    setLoading(true);
    await addToOffersStore.toggleCheckedOffersWithSave(offer.id);
    setLoading(false);

    // Проверяем текущее состояние после toggle
    const nowChecked = addToOffersStore.checkedOffers.includes(offer.id);
    if (nowChecked) {
      // Добавлено
      setAlertMessage('Добавлено в подборку');
      setAlertIcon(addedIcon);
    } else {
      // Удалено
      setAlertMessage('Удалено из подборки');
      setAlertIcon(removedIcon);
    }
    setShowAlert(true);

    // Скрыть уведомление через 3 секунды
    setTimeout(() => {
      setShowAlert(false);
    }, 1500);
  };

  return (
    <div className={cc([styles.container, isChecked && styles.containerActive])}>
      <Checkbox
        disabled={isLoading}
        checked={isChecked}
        name="offer"
        onChange={handleChange}
        label={<OffersCard offer={offer} />}
      />

      {showAlert && (
        <div className={styles.alertWrapper}>
          <Alert
            isShow={true}
            severity={'standard'}
            icon={alertIcon ? alertIcon : ''}
          >
            {alertMessage}
          </Alert>
        </div>
      )}
    </div>
  );
};

export const SelectableOffersCard = observer(SelectableOffersCardRender);

