import React from 'react';
import { useHistory } from 'react-router-dom';
import { appModalRoutesService } from 'services';
import NotFoundOffers from 'assets/NotFoundOffers.svg';
import styles from './styles.module.css';

export const OffersNotFound = () => {
  const { push } = useHistory();
  return (
    <div className={styles.offersNotFoundWrapper}>
      <div className={styles.offersNotFoundIcon}>
        <img src={NotFoundOffers} alt='' />
      </div>
      <h2 className={styles.offersNotFoundTitle}>
        Нет подборок
      </h2>
      <div className={styles.offersNotFoundText}>
        <span>Создайте подборки</span>
        <button onClick={() => push(appModalRoutesService.replaceModalType('offersCreate'))}>Создать</button>
      </div>
    </div>
  );
};
