import React, { ReactNode } from 'react';
import ReactModal from 'react-modal';
import cc from 'classcat';
import closeIconLight from 'assets/closeIconLight.svg';
import closeIconDark from 'assets/closeIconDark.svg';
import styles from './styles.module.css';
import {useTheme} from "../../../../context/theme/useTheme";

type Props = {
  children: ReactNode;
  header?: ReactNode;
  topHeader?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  setForwardedContentRef?: React.Dispatch<
    React.SetStateAction<HTMLDivElement | undefined>
  >;
  isAlbum?: boolean;
};

export const FullscreenPopup = ({
  children,
  header,
  topHeader,
  isOpen,
  onClose,
  setForwardedContentRef,
  isAlbum = false,
}: Props) => {

  const { theme } = useTheme();

  return (
    <ReactModal
      shouldCloseOnEsc
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onClose}
      overlayClassName={cc([styles.modalOverlay, isAlbum && styles.modalOverlayAlbum])}
      className={cc([styles.modalContent, !isAlbum && theme, isAlbum && styles.modalContentAlbum])}
      overlayRef={(ref) => {
        if (setForwardedContentRef) {
          return setForwardedContentRef(ref);
        }
        return null;
      }}
    >
      {topHeader && <div className={styles.topHeader}>{topHeader}</div>}
      {header && (
        <header className={cc([styles.header, isAlbum && styles.headerAlbum])}>
          <div
            className={cc([
              styles.headerContent,
              {
                [styles.headerContentWithTopHeader]: topHeader,
              },
            ])}
          >
            {header}
          </div>
        </header>
      )}
      <button onClick={onClose} type="button" className={cc([styles.closeButton, isAlbum && styles.closeButtonCottage])}>
        <img
          src={isAlbum ? closeIconLight : (theme === 'dark-theme' ? closeIconLight : closeIconDark)}
          className={styles.closeButtonIcon}
          alt="close modal"
        />
      </button>
      <div
        className={cc([
          {
            [styles.content]: !topHeader,
            [styles.contentWithTopHeader]: topHeader,
            [styles.contentAlbum]: isAlbum,
          },
        ])}
      >
        {children}
      </div>
    </ReactModal>
    )
};
