import React, { useMemo } from 'react';
import { Field, FieldArray, Form } from 'formik';
import { observer } from 'mobx-react';
import { FormikProps } from 'formik/dist/types';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';

import { offersEditStore } from 'stores';
import {
  AlertSubmission,
  FormikMaskedInput,
  FormikFieldArray,
  Loader,
  FormikTextField,
  FormikTextareaField,
} from 'components/atoms';
import { isNotNil } from 'utils/fp';
import { OffersEditorFormValues } from '../../types';
import cc from 'classcat';
import { useTheme } from '../../../../../../context/theme/useTheme';
import ordersIconWhite from 'assets/ordersIconWhite.svg'
import closeIconLight from '../../../../../../assets/closeIconLight.svg';
import closeIconDark from '../../../../../../assets/closeIconDark.svg';
import secturityBackground from 'assets/securityBackground.png';
import secturityBackgroundDark from 'assets/securityBackgroundDark.png';
import deleteIcon from 'assets/deleteIcon.svg';
import close from "../../../../../../assets/close.svg";
import styles from './styles.module.css';

type Props = FormikProps<OffersEditorFormValues> & {
  onDeleteOffer?: () => Promise<any>;
  onClose?: () => void;
};

export const FormRenderDataTestIds = {
  submit: 'OffersEditorForm_submit',
  delete: 'OffersEditorForm_delete',
  cancel: 'OffersEditorForm_cancel',
};

export const FormRender = ({
  isSubmitting,
  values,
  errors,
  onDeleteOffer,
  onClose,
  dirty,
  resetForm,
}: Props) => {
  const { isEdit } = offersEditStore;
  const { theme } = useTheme();

  const offerCanBeDelete = useMemo(
    () => isNotNil(values.offerId),
    [values.offerId],
  );

  const title = useMemo(
    () => (isEdit ? 'Редактирование подборки' : 'Создание подборки'),
    [isEdit],
  );

  const saveButtonTitle = useMemo(
    () => (isEdit ? 'Сохранить подборку' : 'Создать подборку'),
    [isEdit],
  );

  const descriptionMaxLength = 350;
  const remainingChars = descriptionMaxLength - (values.description?.length || 0);

  return (
    <div className={styles.formRender}>
      <button onClick={onClose} type="button" className={cc([styles.closeButton])}>
        <img
          src={theme === 'dark-theme' ? closeIconLight : closeIconDark}
          className={styles.closeButtonIcon}
          alt="close modal"
        />
      </button>
      <Form className={styles.formRenderWrapper}>
        <header className={styles.formRenderHeader}>
          <h2 className={styles.formRenderTitle}>{title}</h2>
        </header>
        <header className={styles.formRenderHeaderMobile}>
          <button onClick={onClose} type="button">
            <img
              src={theme === 'dark-theme' ? closeIconLight : closeIconDark}
              className={styles.closeButtonIcon}
              alt="close modal"
            />
          </button>
          <h2 className={styles.formRenderTitle}>{title}</h2>
          <button onClick={onDeleteOffer} data-test-id={FormRenderDataTestIds.delete} type="button">
            <img src={deleteIcon} alt='delete' />
          </button>
        </header>
        <div className={styles.formRenderBody}>
          <div className={styles.formRenderInputGroup}>
            <Field
              label={<><span>Название подборки</span><span className={styles.requiredStar}>*</span></>}
              placeholder="Введите название"
              name="title"
              component={FormikTextField}
              required
            />
            <Field
              label={<><span>Имя клиента (видно клиенту)</span><span className={styles.requiredStar}>*</span></>}
              placeholder="Введите имя"
              name="customer"
              component={FormikTextField}
              required
            />
            <FieldArray name="phones">
              {(props) => (
                <FormikFieldArray
                  label="Номер телефона"
                  component={FormikMaskedInput}
                  componentProps={{
                    pattern: 'mobile',
                    placeholder: 'Для отправки подборки',
                  }}
                  className={styles.formInput}
                  {...props}
                />
              )}
            </FieldArray>

            <FieldArray name="emails">
              {(props) => (
                <FormikFieldArray
                  label="Почта клиента"
                  component={FormikTextField}
                  className={styles.formInput}
                  componentProps={{
                    placeholder: 'Введите почту',
                  }}
                  {...props}
                />
              )}
            </FieldArray>

            <div className={styles.textareaContainerMobile}>
              <Field
                label="Описание подборки (видно только вам)"
                placeholder="Введите описание"
                name="description"
                component={FormikTextareaField}
                maxLength={descriptionMaxLength}
                className={styles.formTextArea}
              />
              <div className={styles.remainingChars}>
                {remainingChars}
              </div>
            </div>

            <Field
              label="Telegram (никнейм)"
              placeholder="Введите имя"
              name="telegram"
              component={FormikTextField}
            />

            <div className={styles.formRenderDisclaimerWrapper}>
              {/*<Icon src={lockIcon} size={32} />*/}
              {/*<div className={styles.formRenderDisclaimerText}>*/}
              {/*  Мы надежно храним данные ваших клиентов и не передаем их третьим лицам*/}
              {/*</div>*/}
              {
                theme === 'light-theme' ? <img src={secturityBackground} alt=""/> :
                  <img src={secturityBackgroundDark} alt=""/>
              }
            </div>
            <div className={styles.requiredInfo}>
              <span className={styles.requiredStarLeft}>*</span>
              Поля помеченные красной звездочкой обязательны для заполнения
            </div>
          </div>

          <div className={styles.textareaContainer}>
            <Field
              label="Описание подборки (видно только вам)"
              placeholder="Введите описание"
              name="description"
              component={FormikTextareaField}
              maxLength={descriptionMaxLength}
              className={styles.formTextArea}
            />
            <div className={styles.remainingChars}>
              {remainingChars}
            </div>
          </div>
        </div>
        <footer className={styles.formRenderFooter}>
          <div className={styles.footerLeft}>
            <ButtonBase
              onClick={onDeleteOffer}
              data-test-id={FormRenderDataTestIds.delete}
              disabled={!offerCanBeDelete || isSubmitting}
            >
              Удалить
            </ButtonBase>
          </div>
          <div className={styles.footerRightBlock}>
            <div className={styles.footerCenter}>
              <ButtonBase
                onClick={() => resetForm()}
                data-test-id={FormRenderDataTestIds.cancel}
                disabled={!dirty || isSubmitting}
                type="button"
              >
                <img src={close} alt="close"/>
                <span>Отменить</span>
              </ButtonBase>
            </div>
            <div className={styles.footerRight}>
              <ButtonBase
                disabled={isSubmitting}
                data-test-id={FormRenderDataTestIds.submit}
                icon={isSubmitting && <Loader color="primary"/>}
                color="primary"
                type="submit"
              >
                <img src={ordersIconWhite} alt="orders"/>
                <span>{saveButtonTitle}</span>
              </ButtonBase>
            </div>
          </div>
        </footer>
      </Form>
      <AlertSubmission isSubmitting={isSubmitting} errors={errors}/>
    </div>
  );
};

export const FormRenderWithObserver = observer(FormRender);

