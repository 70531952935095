import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { TableRow } from '../TableRow/TableRow';
import styles from './GeneralPlanTable.module.scss';
import { observer } from 'mobx-react';
import { villageStore } from '../../../../../../stores';
import { PlanToggle } from '../PlanToggle/PlanToggle';
import { Cottage, Section } from '../../../../../../services/v1/villageService/types';
import { CottageView } from "../CottageView/CottageView";
import { useHistory, useParams } from "react-router-dom";
import { appRoutesService, BuildingSearchParameters } from "../../../../../../services";
import { NoResults } from "../NoResults/NoResults";

interface RouteParams {
  complexId: string;
  generalPlanId: string;
  viewType: string;
  sectionId: string;
  cottageId?: string;
  cottageNumber?: string;
}

interface GeneralPlanTableProps {
  villageId: string;
  sections: Section[];
  selectedSectionId: number | null;
  setSelectedSectionId: Dispatch<SetStateAction<number | null>>
  filtersForAPI?: Partial<BuildingSearchParameters>;
}

export const GeneralPlanTable: FC<GeneralPlanTableProps> = observer((
  {
    villageId,
    sections,
    selectedSectionId,
    setSelectedSectionId,
    filtersForAPI,
  }) => {
  const [cottages, setCottages] = useState<Cottage[]>([]);
  const [loadingCottages, setLoadingCottages] = useState<boolean>(false);
  const [isApartmentViewOpen, setIsApartmentViewOpen] = useState<boolean>(false);
  const [selectedCottage, setSelectedCottage] = useState<Cottage | null>(null);
  const history = useHistory();
  const params = useParams<RouteParams>();

  useEffect(() => {
    if (sections.length > 0 && selectedSectionId === null) {
      const sectionWithCottages = sections.find((section) => section.stats.apartments > 0);
      if (sectionWithCottages) {
        setSelectedSectionId(sectionWithCottages.id);
      } else {
        setSelectedSectionId(sections[0].id);
      }
    }
  }, [sections, selectedSectionId]);

  useEffect(() => {
    if (selectedSectionId !== null) {
      setLoadingCottages(true);
      villageStore
        .fetchCottages({
          complexIds: [villageId],
          sectionIds: [selectedSectionId],
          filtersForAPI,
          limit: 10000,
        })
        .then(() => {
          setCottages(villageStore.cottages);
          setLoadingCottages(false);
        });
    }
  }, [selectedSectionId, villageId, filtersForAPI]);

  const sectionCounts: { [key: number]: number } = {};
  sections.forEach((section) => {
    sectionCounts[section.id] = section.stats.apartments || 0;
  });

  useEffect(() => {
    if (params.cottageId && cottages.length > 0) {
      const cottage = cottages.find((c) => c.id === Number(params.cottageId));
      if (cottage) {
        setSelectedCottage(cottage);
        setIsApartmentViewOpen(true);
      }
    }
  }, [cottages, params.cottageId]);

  const onRowClick = (cottage: Cottage) => {
    const newParams = {
      ...params,
      cottageId: cottage.id.toString(),
      cottageNumber: cottage.number,
    };
    const newRoute = appRoutesService.replaceRoute('generalPlanCottage', newParams);
    history.push(newRoute);

    setSelectedCottage(cottage);
    setIsApartmentViewOpen(true);
  };

  const closeApartmentView = () => {
    const { cottageId, cottageNumber, ...restParams } = params;
    const newRoute = appRoutesService.replaceRoute('generalPlan', restParams);
    history.push(newRoute);

    setSelectedCottage(null);
    setIsApartmentViewOpen(false);
  };

  const areFiltersApplied = filtersForAPI && Object.keys(filtersForAPI).length > 0;

  if (cottages.length === 0 && !loadingCottages && areFiltersApplied) {
    return (
      <NoResults />
    );
  }

  return (
    <div className={styles.generalPlanTable}>
      <PlanToggle
        sections={sections}
        selectedSectionId={selectedSectionId}
        onSelectSection={(sectionId) => {
          if (sectionId !== selectedSectionId) {
            setSelectedSectionId(sectionId);
            setCottages([]);
            setSelectedCottage(null);
            setIsApartmentViewOpen(false);
          }
        }}
        sectionCounts={sectionCounts}
      />

      <div className={styles.tableHeader}>
        <div>Статус</div>
        <div>План</div>
        <div>№</div>
        <div>Этажи</div>
        <div>Спальни</div>
        <div>Тип</div>
        <div>Площадь участка</div>
        <div>Площадь</div>
        <div>₽ / м²</div>
        <div>Стоимость</div>
        <div>Материал</div>
        <div>Отделка</div>
        <div></div>
      </div>
      {cottages.length === 0 ? (
        <div></div>
      ) : (
        cottages.map((cottage) => (
          <TableRow key={cottage.id} data={cottage} onRowClick={onRowClick} />
        ))
      )}

      {selectedCottage && (
        <CottageView
          onClose={closeApartmentView}
          apartment={selectedCottage}
          isOpen={isApartmentViewOpen}
        />
      )}
    </div>
  );
});




