import React from 'react';
import { useTheme } from "../../context/theme/useTheme";

const AddCollectionIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 6H20M4 12H20M4 18H11M15 18H18M18 18H21M18 18V15M18 18V21" stroke="#E1E4EC" strokeWidth="2"
              strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
  )
:
  (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 6H20M4 12H20M4 18H11M15 18H18M18 18H21M18 18V15M18 18V21" stroke="#333338" strokeWidth="2"
            strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
  );
};

export default AddCollectionIcon;
