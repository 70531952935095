import React from 'react';

type Props = {
  isActive: boolean;
}

const GenplanListIcon = ({isActive}: Props) => {
  return (
    isActive ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.66901 4.49935V4.49935H2.66667C2.60398 4.49935 2.57528 4.48362 2.54617 4.45441C2.51607 4.4242 2.50015 4.39434 2.5 4.3315C2.49986 4.27053 2.51499 4.24203 2.54494 4.21218C2.57589 4.18134 2.60555 4.16602 2.66667 4.16602C2.72781 4.16602 2.75804 4.18138 2.78967 4.21279C2.81925 4.24216 2.83377 4.26949 2.83335 4.32914C2.83289 4.39386 2.81616 4.42567 2.78533 4.45693C2.75854 4.48409 2.73198 4.49965 2.66901 4.49935ZM2.66901 8.16586V8.16585H2.66667C2.60398 8.16585 2.57528 8.15012 2.54617 8.12091C2.51607 8.09071 2.50015 8.06084 2.5 7.99801C2.49986 7.93704 2.51499 7.90853 2.54494 7.87868C2.57589 7.84784 2.60555 7.83252 2.66667 7.83252C2.72781 7.83252 2.75804 7.84788 2.78967 7.87929C2.81925 7.90866 2.83377 7.936 2.83335 7.99565C2.83289 8.06037 2.81616 8.09218 2.78533 8.12344C2.75854 8.1506 2.73198 8.16615 2.66901 8.16586ZM2.66901 11.8328H2.66667C2.60398 11.8328 2.57528 11.8171 2.54617 11.7879C2.51607 11.7577 2.50015 11.7278 2.5 11.665C2.49986 11.604 2.51499 11.5755 2.54494 11.5457C2.57589 11.5148 2.60555 11.4995 2.66667 11.4995C2.72781 11.4995 2.75804 11.5149 2.78967 11.5463C2.81925 11.5757 2.83377 11.603 2.83335 11.6626C2.83289 11.7274 2.81616 11.7592 2.78533 11.7904C2.75854 11.8176 2.73198 11.8331 2.66901 11.8328ZM5.16667 4.16602H13.5V4.49935H5.16667V4.16602ZM5.16667 8.16585V7.83252H13.5V8.16585H5.16667ZM5.16667 11.8328V11.4995H13.5V11.8328H5.16667Z"
          fill="#4E6AFF" stroke="#4E6AFF"/>
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.66667 4.99935V3.66602H14V4.99935H4.66667ZM4.66667 8.66585V7.33252H14V8.66585H4.66667ZM4.66667 12.3328V10.9995H14V12.3328H4.66667ZM2.66667 4.99935C2.47778 4.99935 2.31956 4.93535 2.192 4.80735C2.06445 4.67935 2.00045 4.52113 2 4.33268C1.99956 4.14424 2.06356 3.98602 2.192 3.85802C2.32045 3.73002 2.47867 3.66602 2.66667 3.66602C2.85467 3.66602 3.01311 3.73002 3.142 3.85802C3.27089 3.98602 3.33467 4.14424 3.33334 4.33268C3.332 4.52113 3.268 4.67957 3.14134 4.80802C3.01467 4.93646 2.85645 5.00024 2.66667 4.99935ZM2.66667 8.66585C2.47778 8.66585 2.31956 8.60185 2.192 8.47385C2.06445 8.34585 2.00045 8.18763 2 7.99919C1.99956 7.81074 2.06356 7.65252 2.192 7.52452C2.32045 7.39652 2.47867 7.33252 2.66667 7.33252C2.85467 7.33252 3.01311 7.39652 3.142 7.52452C3.27089 7.65252 3.33467 7.81074 3.33334 7.99919C3.332 8.18763 3.268 8.34608 3.14134 8.47452C3.01467 8.60296 2.85645 8.66674 2.66667 8.66585ZM2.66667 12.3328C2.47778 12.3328 2.31956 12.2688 2.192 12.1408C2.06445 12.0128 2.00045 11.8546 2 11.6662C1.99956 11.4777 2.06356 11.3195 2.192 11.1915C2.32045 11.0635 2.47867 10.9995 2.66667 10.9995C2.85467 10.9995 3.01311 11.0635 3.142 11.1915C3.27089 11.3195 3.33467 11.4777 3.33334 11.6662C3.332 11.8546 3.268 12.0131 3.14134 12.1415C3.01467 12.27 2.85645 12.3337 2.66667 12.3328Z"
          fill="#333338"/>
      </svg>
    )
  );
};

export default GenplanListIcon;
