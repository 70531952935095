import React from 'react';
import { observer } from 'mobx-react';
import { infoStore } from 'stores/InfoStore';
import { DictionaryItem, DictionaryKeys } from 'services';
import { ContextValueVillageFilters } from 'context';
import { FilterStore } from 'stores';
import { BuildingFilterType } from 'stores/BuildingFilters';
import { ButtonGroupSelect } from '../ButtonGroupSelect/ButtonGroupSelect';

type Button = {
  label: string;
  value: string;
  id: number | string;
};

type Props = {
  filterKey: any;
  placeholder?: string;
  dictKey?: DictionaryKeys;
  disabled?: boolean;
  store:
    | FilterStore<any, any>
    | { filters: ContextValueVillageFilters['villageDataFilter'] };
  filterDictionaryItems?: (dictItems: DictionaryItem[]) => DictionaryItem[];
  onChangeVillageTypeHouseContext?: any;
  multiselect?: boolean;
  values?: Record<string, string>;
  buttons?: Button[]; // Новый проп для пользовательских кнопок
};

export const ReactiveButtonGroupSelect = observer(
  ({
     placeholder = 'Выбрать',
     filterKey,
     dictKey,
     disabled,
     store,
     filterDictionaryItems,
     onChangeVillageTypeHouseContext,
     multiselect = true,
     values,
     buttons,
   }: Props) => {
    const filterItem: string[] = store.filters[filterKey];
    let items: Record<string, string> = {};

    if (buttons && buttons.length > 0) {
      // Используем пользовательские кнопки
      items = Object.fromEntries(buttons.map((btn) => [btn.value, btn.label]));
    } else if (dictKey) {
      // Используем словарь
      let selectItems: DictionaryItem[];

      if (
        filterDictionaryItems &&
        typeof filterDictionaryItems === 'function'
      ) {
        selectItems =
          filterDictionaryItems(infoStore.dictionaries[dictKey]?.items || []) ||
          [];
      } else {
        selectItems = infoStore.dictionaries[dictKey]?.items || [];
      }

      items =
        values ??
        Object.fromEntries(
          selectItems.map((dictItem) => [dictItem.id, dictItem.value]),
        );
    } else {
      console.error(
        'ReactiveButtonGroupSelect: Необходимо передать либо buttons, либо dictKey',
      );
      return null;
    }

    return (
      <ButtonGroupSelect
        items={items}
        value={filterItem || []}
        placeholder={placeholder}
        onChange={(newValue) => {
          if (onChangeVillageTypeHouseContext) {
            onChangeVillageTypeHouseContext?.({
              target: { name: filterKey, value: newValue },
            });
            return;
          }

          if (store instanceof FilterStore) {
            store.setField(
              filterKey as keyof BuildingFilterType,
              newValue as string[],
            );
          } else if (store.filters) {
            store.filters[filterKey] = newValue;
          }
        }}
        multiselect={multiselect}
        disabled={disabled}
      />
    );
  },
);

