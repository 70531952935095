import React from 'react';
import { head } from 'lodash/fp';
import { Cottage } from '../../../../../../services/v1/villageService/types';
import { PlaceholderImage } from 'components/atoms';
import styles from './CottageInfoTooltip.module.scss';
import {GridCottageInfo} from "../GridCottageInfo/GridCottageInfo";

type Props = {
  cottage: Cottage;
};

export const CottageInfoTooltip: React.FC<Props> = ({ cottage }) => {

  const transformedCottage = {
    ...cottage,
    catalogs: cottage.catalogs || {},
  };

  const cottagePlans: string | undefined | null = head(
    transformedCottage.layouts?.map(layout => layout.photo)
  );

  return (
    <div className={styles.apartmentInfoTooltipWrapper}>
      <div className={styles.apartmentInfoTooltipLeft}>
        {cottagePlans ? (
          <img className={styles.apartmentInfoTooltipPlan} src={cottagePlans} alt="" />
        ) : (
          <PlaceholderImage type="FLOOR_PLAN" />
        )}
      </div>
      <div className={styles.apartmentInfoTooltipRight}>
        <GridCottageInfo apartment={transformedCottage} />
      </div>
    </div>
  );
};
