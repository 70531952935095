import React, { memo } from 'react';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { requestApiInstance } from 'stores/request/RequestApiStore';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from './ReactiveInput';
import styles from '../../AllFilters/AllFilters.module.scss';
import { ContextValueVillageFilters } from 'context';
import {ReactiveButtonGroupSelect} from "../../../atoms/ReactiveButtonGroupSelect/ReactiveButtonGroupSelect";

type Props = {
  isShowFromVillage?: boolean,
  store?: { filters: ContextValueVillageFilters['villageDataFilter'] },
  handleChangeStore?:ContextValueVillageFilters["handleChangeVillageFilter"]
}

export const FirstColumnFilter = memo(({isShowFromVillage, store, handleChangeStore}: Props) => {
  const { districts } = infoStore;
  const { isLoading } = requestApiInstance;  

  return (
    <>
      <span className={styles.formLabel}>Стоимость</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceMin"
          placeholder=""
          prefixText="от"
          suffixIcon="₽"
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceMax"
          placeholder=""
          prefixText="до"
          suffixIcon="₽"
          clearable={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип дома</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="villageTypeHouse"
          dictKey="village_type_house"
          onChangeVillageTypeHouseContext={handleChangeStore}
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Статус строительства</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="constructionPhase"
          dictKey="construction_phase_status"
          onChangeVillageTypeHouseContext={handleChangeStore}
          multiselect={true}
        />
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Преимущества</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageAdvantages"
          dictKey="residential_complex_advantages"
        />
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Этажность</span>
        <ReactiveButtonGroupSelect
          buttons={[
            {label: '1 этаж', value: '1', id: 1},
            {label: '2 этажа', value: '2', id: 2},
            {label: '3+ этажа', value: '3+', id: 3},
          ]}
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageFloor"
          multiselect={true}
        />
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Количество спален</span>
        <ReactiveButtonGroupSelect
          buttons={[
            {label: "1", value: '1', id: 1},
            {label: "2", value: '2', id: 2},
            {label: "3+", value: '3+', id: 3}
          ]}
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageBedrooms"

        />
      </div>
      <div>
        <span className={styles.formLabel}>Площадь дома</span>
        <div className={styles.formInputs}>
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="squareMin"
            placeholder=""
            prefixText="от"
            suffixIcon="м²"
            clearable={true}
          />
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="squareMax"
            placeholder=""
            prefixText="до"
            suffixIcon="м²"
            clearable={true}
          />
        </div>
      </div>
      <div>
        <span className={styles.formLabel}>Площадь участка</span>
        <div className={styles.formInputs}>
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="squareLandMin"
            placeholder=""
            prefixText="от"
            suffixIcon="Сотка"
            clearable={true}
          />
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="squareLandMax"
            placeholder=""
            prefixText="до"
            suffixIcon="Сотка"
            clearable={true}
          />
        </div>
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Материал здания</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey='villageMaterial'
          dictKey='village_material'
          multiselect={true}
        />
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Отделка</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey='villageFacade'
          dictKey='village_facade'
          multiselect={true}
        />
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Гараж</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageGarage"
          dictKey="village_garage"
        />
      </div>
      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Количество машиномест</span>*/}
      {/*  <ReactiveButtonGroupSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*    filterKey="parkingSpaces"*/}
      {/*    multiselect={true}*/}
      {/*  />*/}
      {/*</div>*/}
      <div className={styles.formSubColumnWrapper}>
        <div className={styles.formSingleItem}>
          <span className={styles.formLabel}>Дворовая территория</span>
          <ReactiveButtonGroupSelect
            store={store || complexFilterStore.filters}
            onChangeVillageTypeHouseContext={handleChangeStore}
            filterKey="villageYardTerritory"
            dictKey="village_yard_territory"
            multiselect={true}
          />
        </div>
      </div>

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Район</span>*/}
      {/*  <PopupDistrictsChoicer*/}
      {/*    data={districts || []}*/}
      {/*    filterKey="districtIds"*/}
      {/*    store={complexFilterStore.filters}*/}
      {/*    disabled={isLoading || isShowFromVillage}*/}
      {/*    placeholder="Любой"*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={styles.formSubColumnWrapper}>*/}
      {/*  <div className={styles.formItem}>*/}
      {/*    <span className={styles.formLabel}>Тип участка</span>*/}
      {/*    <ReactiveSelect*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="villageTypeLand"*/}
      {/*      dictKey="village_type_land"*/}
      {/*      placeholder="Не важно"*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*  </div>*/}

      {/*  <div className={styles.formItem}>*/}
      {/*    <span className={styles.formLabel}>ВРИ</span>*/}
      {/*    <ReactiveSelect*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="villageVRI"*/}
      {/*      dictKey="village_vri"*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className={styles.formInputs}>*/}
      {/*  <span className={styles.formLabel}>Расстояние до города</span>*/}
      {/*  <InputGroup>*/}
      {/*    <ReactiveInput*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="distanceToCityMin"*/}
      {/*      placeholder="От"*/}
      {/*      className={styles.complexInputLeft}*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*    <ReactiveInput*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="distanceToCityMax"*/}
      {/*      placeholder="До"*/}
      {/*      className={styles.complexInputRight}*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*  </InputGroup>*/}
      {/*</div>*/}
      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Дата обновления КП</span>*/}
      {/*  <ReactiveSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    filterKey="publishPeriod"*/}
      {/*    placeholder="Любой"*/}
      {/*    onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*    values={{day: '24 часа', week: 'За неделю', month: 'За месяц'}}*/}
      {/*    multiselect={false}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={styles.formInputs}>*/}
      {/*  <span className={styles.formLabel}>Агентское вознаграждение, %</span>*/}
      {/*  <InputGroup>*/}
      {/*    <ReactiveInput*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="agentCommissionPercentMin"*/}
      {/*      placeholder="От"*/}
      {/*      className={styles.complexInputLeft}*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*    <ReactiveInput*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="agentCommissionPercentMax"*/}
      {/*      placeholder="До"*/}
      {/*      className={styles.complexInputRight}*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*  </InputGroup>*/}
      {/*</div>*/}
    </>
  );
});
