import React, { ReactNode, CSSProperties, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';
import { isNil } from 'lodash';
import { FilterStore } from 'stores/FilterStore';
import styles from './styles.module.css';

type Props = {
  filterKey: any;
  store: FilterStore<any, any>;
  placeholder?: string;
  prefixText?: string;
  suffixIcon?: ReactNode;
  clearable?: boolean;
  className?: string;
  disabled?: boolean;
  customStyles?: CSSProperties;
};

export const ReactiveInputRender = ({
  filterKey,
  store,
  placeholder = 'Выбрать',
  prefixText,
  suffixIcon,
  clearable = false,
  className,
  disabled,
  customStyles,
}: Props) => {
  // Достаем значение фильтра из store
  const filterItem: number | undefined = store.filters[filterKey];

  // Преобразуем undefined или 0 в пустую строку (логика сохранена)
  const initialValue = String(isNil(filterItem) || filterItem === 0 ? '' : filterItem);

  const [inputValue, setInputValue] = useState(initialValue);

  useEffect(() => {
    // Если значение в store изменилось извне, обновим локальное состояние
    const updatedValue = String(isNil(filterItem) || filterItem === 0 ? '' : filterItem);
    if (updatedValue !== inputValue) {
      setInputValue(updatedValue);
    }
  }, [filterItem, inputValue]);

  const formatValue = (value: string | number) =>
    value
      ? String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      : '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s/g, ''); // Убираем пробелы
    if (!isNaN(Number(value))) {
      setInputValue(value);
      // Сохраняем число в store
      store.setField(filterKey, value === '' ? undefined : Number(value));
    }
  };

  const handleClear = () => {
    setInputValue('');
    store.setField(filterKey, undefined);
  };

  return (
    <div className={cc([className, styles.inputWrapper])}>
      {prefixText && <span className={styles.prefixText}>{prefixText}</span>}
      <input
        type="text"
        placeholder={placeholder}
        value={formatValue(inputValue)}
        onChange={handleChange}
        disabled={disabled}
        className={cc([
          styles.inputTag,
          { [styles.inputTagWithSuffix]: suffixIcon || clearable },
        ])}
        style={customStyles}
      />
      {suffixIcon && (
        <span className={cc([styles.suffixIcon, inputValue && styles.suffixIconClose])}>
          {suffixIcon}
        </span>
      )}
      {clearable && inputValue && (
        <button
          type="button"
          className={styles.clearButton}
          onClick={handleClear}
        >
          ✕
        </button>
      )}
    </div>
  );
};

export const ReactiveInput = observer(ReactiveInputRender);

