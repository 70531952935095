import React, { ReactElement, CSSProperties, Key } from 'react';
import cc from 'classcat';

import closeBtnDark from 'assets/closeIconDark.svg';
import closeBtnLight from 'assets/closeIconLight.svg';

import styles from './styles.module.css';
import { useTheme } from "../../../context/theme/useTheme";

type ButtonType = 'button' | 'submit' | 'reset';

type Props = {
  onClick?: () => void;
  onDelete?: (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => void;
  children: ReactElement | string;
  className?: string;
  type?: ButtonType;
  style?: CSSProperties;
  size?: 'LG' | 'MD';
  isSelected?: boolean | null;
  disabled?: boolean;
  key: Key
};

export const TagButton = ({
  onClick,
  type = 'button',
  className = '',
  onDelete,
  size = 'MD',
  children,
  style = {},
  isSelected,
  disabled,
  key,
}: Props) => {
  const { theme } = useTheme()

  return (
    /* eslint-disable react/button-has-type */
    <button
      key={key}
      type={type}
      onClick={onClick}
      className={cc([
        styles.filterButton,
        {
          [styles.largeFilterBtn]: size === 'LG',
          [styles.selectedButton]: isSelected,
          [styles.disabledButton]: disabled,
        },
        className,
      ])}
      style={style}
    >
      <span>{children}</span>
      {isSelected && (
        <span className={styles.closeButton}>
        <img
          src={theme === 'dark-theme' ? closeBtnLight : closeBtnDark}
          className={styles.closeButtonIcon}
          alt="close modal"
          onClick={(e) => onDelete?.(e)}
        />
      </span>
      )}
    </button>
  )
};
