import { useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Params } from 'services';
import {
  TabItemProps,
  TabProps,
  useTabs,
} from 'components/containers/Tabs/hooks/useTabs';
import { getActiveTabByRouteName, getUrlForCurrentActiveTab } from '../utils';
import { LayoutTabs } from '../types';

// Набор табов для коттеджного поселка
const VILLAGE_TAB_LIST: TabItemProps<undefined>[] = [
  {
    label: 'Генплан',
  },
  {
    label: 'Список',
  },
];

// Набор стандартных табов
const DEFAULT_TAB_LIST: TabItemProps<undefined>[] = [
  {
    label: 'Шахматка',
  },
  {
    label: 'План этажа',
  },
  {
    label: 'Список',
  },
];

// Функция хука с дополнительным параметром isVillage (по умолчанию false)
export const useLayoutTabsState = (
  isVillage: boolean = false
): Omit<TabProps<string>, 'setTabList'> => {
  const { push } = useHistory();

  // Извлекаем параметры из URL
  const { complexId, layoutTabs, apartmentId, buildingId, apartmentNumber } =
    useParams<Params>();

  // Определяем, какие табы использовать
  const TAB_LIST = isVillage ? VILLAGE_TAB_LIST : DEFAULT_TAB_LIST;

  const { tabList, changeTab, currentActiveTab } = useTabs<string>({
    tabList: TAB_LIST,
    currentActiveTab: getActiveTabByRouteName(layoutTabs as LayoutTabs),
  });

  /**
   * Обновление маршрута при изменении активной табы
   */
  useEffect(() => {
    if (currentActiveTab === getActiveTabByRouteName(layoutTabs as LayoutTabs)) {
      return;
    }

    const url = getUrlForCurrentActiveTab(currentActiveTab, {
      complexId,
      buildingId,
      apartmentId,
      apartmentNumber,
    });
    push(url);
  }, [currentActiveTab]);

  return { tabList, changeTab, currentActiveTab };
};
