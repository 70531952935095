import React from 'react';
import { useTheme } from "../../context/theme/useTheme";

const PhoneIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4.75937 5.87651L5.91478 4.6679C6.12486 4.45771 6.2299 3.98477 5.91478 3.66948L3.70899 1.19971C3.70899 1.19971 3.1838 0.831875 2.71113 1.09462C2.29098 1.35736 0.61038 2.67106 1.08305 5.56122C1.60824 8.76666 7.22776 14.4419 10.4314 14.9148C13.3199 15.3877 14.6329 13.7588 14.8955 13.2858C15.1581 12.8129 14.843 12.2874 14.843 12.2874L12.3221 10.0804C11.9019 9.65999 11.5343 9.87018 11.3242 10.0804L10.1688 11.2364C9.27599 11.867 4.12914 6.71728 4.75937 5.87651Z"
              fill="#585B68"/>
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4.75937 5.87651L5.91478 4.6679C6.12486 4.45771 6.2299 3.98477 5.91478 3.66948L3.70899 1.19971C3.70899 1.19971 3.1838 0.831875 2.71113 1.09462C2.29098 1.35736 0.61038 2.67106 1.08305 5.56122C1.60824 8.76666 7.22776 14.4419 10.4314 14.9148C13.3199 15.3877 14.6329 13.7588 14.8955 13.2858C15.1581 12.8129 14.843 12.2874 14.843 12.2874L12.3221 10.0804C11.9019 9.65999 11.5343 9.87018 11.3242 10.0804L10.1688 11.2364C9.27599 11.867 4.12914 6.71728 4.75937 5.87651Z"
              fill="#AFB4CB"/>
      </svg>
    )
  );
};

export default PhoneIcon;
