import React, { FC, useEffect, useState } from 'react';
import styles from './TelegramComponent.module.scss';
import closeIcon from 'assets/closeIconWhite.svg';
import qrTgIcon from 'assets/qrTgIcon.svg';
import flatbyIcon from 'assets/flatbyIcon.svg';

export const TelegramComponent: FC = () => {
  const [isClosed, setIsClosed] = useState(false);

  useEffect(() => {
    const closed = localStorage.getItem('telegramBannerClosed');
    if (closed === 'true') {
      setIsClosed(true);
    }
  }, []);

  const handleClose = (event: React.MouseEvent) => {
    event.stopPropagation();
    setIsClosed(true);
    localStorage.setItem('telegramBannerClosed', 'true');
  };

  if (isClosed) return null;

  return (
    <div className={styles.bannerWrapper}>
      <a
        href="https://t.me/flatby_pro"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.banner}
      >
        <div className={styles.content}>
          <div className={styles.qrContainer}>
            <img src={qrTgIcon} alt="QR code" />
          </div>

          <div className={styles.textContainer}>
            <img className={styles.logo} src={flatbyIcon} alt="Flatby logo" />
            <h2 className={styles.title}>Будьте в курсе последних событий</h2>
            <p className={styles.description}>
              Подпишитесь на наш Telegram-канал и узнавайте первыми о новых проектах,
              разработчиках и событиях
            </p>
          </div>
        </div>
      </a>

      <button
        type="button"
        className={styles.closeButton}
        onClick={handleClose}
        aria-label="Закрыть"
      >
        <img src={closeIcon} alt="close" />
      </button>
    </div>
  );
};
