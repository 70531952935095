import { useEffect } from 'react';
import L, { Map as MapType } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-plugins/layer/tile/Yandex';
import { useMap } from 'react-leaflet';
import { MayBe } from 'types';
import { useTheme } from "../../../../context/theme/useTheme";

type Props = {
  mapInstance?: MayBe<MapType>;
  theme?: string;
};

export const YandexMapLayer = ({ mapInstance }: Props) => {
  const map = useMap();
  const { theme } = useTheme();

  useEffect(() => {
    const _map = mapInstance || map;
    // Удаляем все слои перед добавлением нового
    // _map.eachLayer((layer) => {
    //   _map.removeLayer(layer);
    // });

    // Определяем URL тайлов с учетом темы
    const yandexTileUrl =
      theme === 'dark-theme'
        ? 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.03.12&x={x}&y={y}&z={z}&scale=1&lang=ru_RU&theme=dark'
        : 'https://core-renderer-tiles.maps.yandex.net/tiles?l=map&v=21.03.12&x={x}&y={y}&z={z}&scale=1&lang=ru_RU';

    // Добавляем слой Яндекс.Карт
    L.tileLayer(yandexTileUrl, {
      attribution: '&copy; Яндекс',
      minZoom: 0,
      maxZoom: 19,
    }).addTo(_map);

    // const baseLayers = {
    //   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //   // @ts-ignore
    //   'Yandex map': L.yandex() // 'map' is default
    //     .addTo(_map),
    // };
    // L.control
    //   .layers(baseLayers, undefined, {
    //     collapsed: false,
    //     hideSingleBase: true,
    //   })
    //   .addTo(_map);
  }, [map, mapInstance, theme]);

  return null;
};
