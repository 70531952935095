import React from 'react';
import {useTheme} from "../../context/theme/useTheme";

const RefreshIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.069 12.3863L12.1034 11.4208V10.1724H11.1379V11.8206L12.3863 13.069L13.069 12.3863Z"
              fill="#585B68"/>
        <path
          d="M11.6207 15C10.9523 15 10.299 14.8018 9.74325 14.4305C9.18752 14.0592 8.75439 13.5314 8.49861 12.9139C8.24284 12.2964 8.17592 11.6169 8.30631 10.9614C8.4367 10.3059 8.75855 9.70376 9.23116 9.23116C9.70376 8.75855 10.3059 8.4367 10.9614 8.30631C11.6169 8.17592 12.2964 8.24284 12.9139 8.49861C13.5314 8.75439 14.0592 9.18752 14.4305 9.74325C14.8018 10.299 15 10.9523 15 11.6207C14.999 12.5166 14.6426 13.3756 14.0091 14.0091C13.3756 14.6426 12.5166 14.999 11.6207 15ZM11.6207 9.2069C11.1433 9.2069 10.6766 9.34846 10.2797 9.61369C9.88271 9.87892 9.57333 10.2559 9.39064 10.697C9.20794 11.138 9.16014 11.6234 9.25328 12.0916C9.34641 12.5598 9.57631 12.9899 9.91388 13.3275C10.2515 13.6651 10.6816 13.895 11.1498 13.9881C11.618 14.0812 12.1033 14.0334 12.5444 13.8507C12.9855 13.668 13.3625 13.3587 13.6277 12.9617C13.8929 12.5648 14.0345 12.0981 14.0345 11.6207C14.0338 10.9807 13.7792 10.3672 13.3267 9.91466C12.8742 9.46213 12.2607 9.2076 11.6207 9.2069Z"
          fill="#585B68"/>
        <path
          d="M7.75862 13.5517C6.22272 13.55 4.75022 12.9391 3.66417 11.8531C2.57812 10.767 1.96723 9.29452 1.96552 7.75862H1C1.00202 9.5505 1.71473 11.2684 2.98178 12.5355C4.24883 13.8025 5.96674 14.5152 7.75862 14.5172V13.5517Z"
          fill="#585B68"/>
        <path
          d="M5.82759 3.89655H3.45164C4.23289 3.02204 5.26144 2.40558 6.40098 2.12887C7.54052 1.85215 8.73725 1.92825 9.83256 2.34708C10.9279 2.7659 11.8701 3.50768 12.5342 4.4741C13.1984 5.44053 13.5533 6.58597 13.5517 7.75862H14.5172C14.5194 6.41499 14.1203 5.10132 13.3713 3.98588C12.6222 2.87044 11.5571 2.00402 10.3126 1.49762C9.06804 0.991214 7.70065 0.867878 6.38558 1.14341C5.07051 1.41894 3.86763 2.0808 2.93103 3.04419V1H1.96552V4.86207H5.82759V3.89655Z"
          fill="#585B68"/>
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.069 12.3863L12.1034 11.4208V10.1724H11.1379V11.8206L12.3863 13.069L13.069 12.3863Z"
              fill="#AFB4CB"/>
        <path
          d="M11.6207 15C10.9523 15 10.299 14.8018 9.74325 14.4305C9.18752 14.0592 8.75439 13.5314 8.49861 12.9139C8.24284 12.2964 8.17592 11.6169 8.30631 10.9614C8.4367 10.3059 8.75855 9.70376 9.23116 9.23116C9.70376 8.75855 10.3059 8.4367 10.9614 8.30631C11.6169 8.17592 12.2964 8.24284 12.9139 8.49861C13.5314 8.75439 14.0592 9.18752 14.4305 9.74325C14.8018 10.299 15 10.9523 15 11.6207C14.999 12.5166 14.6426 13.3756 14.0091 14.0091C13.3756 14.6426 12.5166 14.999 11.6207 15ZM11.6207 9.2069C11.1433 9.2069 10.6766 9.34846 10.2797 9.61369C9.88271 9.87892 9.57333 10.2559 9.39064 10.697C9.20794 11.138 9.16014 11.6234 9.25328 12.0916C9.34641 12.5598 9.57631 12.9899 9.91388 13.3275C10.2515 13.6651 10.6816 13.895 11.1498 13.9881C11.618 14.0812 12.1033 14.0334 12.5444 13.8507C12.9855 13.668 13.3625 13.3587 13.6277 12.9617C13.8929 12.5648 14.0345 12.0981 14.0345 11.6207C14.0338 10.9807 13.7792 10.3672 13.3267 9.91466C12.8742 9.46213 12.2607 9.2076 11.6207 9.2069Z"
          fill="#AFB4CB"/>
        <path
          d="M7.75862 13.5517C6.22272 13.55 4.75022 12.9391 3.66417 11.8531C2.57812 10.767 1.96723 9.29452 1.96552 7.75862H1C1.00202 9.5505 1.71473 11.2684 2.98178 12.5355C4.24883 13.8025 5.96674 14.5152 7.75862 14.5172V13.5517Z"
          fill="#AFB4CB"/>
        <path
          d="M5.82759 3.89655H3.45164C4.23289 3.02204 5.26144 2.40558 6.40098 2.12887C7.54052 1.85215 8.73725 1.92825 9.83256 2.34708C10.9279 2.7659 11.8701 3.50768 12.5342 4.4741C13.1984 5.44053 13.5533 6.58597 13.5517 7.75862H14.5172C14.5194 6.41499 14.1203 5.10132 13.3713 3.98588C12.6222 2.87044 11.5571 2.00402 10.3126 1.49762C9.06804 0.991214 7.70065 0.867878 6.38558 1.14341C5.07051 1.41894 3.86763 2.0808 2.93103 3.04419V1H1.96552V4.86207H5.82759V3.89655Z"
          fill="#AFB4CB"/>
      </svg>
    )
  );
};

export default RefreshIcon;
