import { FC, useState } from 'react';
import styles from './TextDescription.module.scss';
import arrowDropdownIcon from '../../../assets/arrow-dropdown.svg';

interface Props {
  description: string;
  className?: string;
}

export const TextDescription: FC<Props> = ({ description, className }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Функция для переключения состояния текста
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  // Создаем временный элемент для извлечения чистого текста
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = description;
  const plainText = tempDiv.textContent || tempDiv.innerText || '';

  // Условие для определения длинного текста
  const isLongText = plainText.length > 200;

  // Обрезанное описание
  const truncatedDescription = isLongText && !isExpanded
    ? `${plainText.slice(0, 200)}...`
    : plainText;

  return (
    <div className={`${styles.textDescription} ${className}`}>
      <h3 className={styles.title}>Описание</h3>
      <div
        className={styles.text}
        dangerouslySetInnerHTML={{ __html: isExpanded ? description : truncatedDescription }}
      />
      {isLongText && (
        <div className={styles.toggleWrapper}>
          <button className={styles.toggleButton} onClick={toggleText}>
            {isExpanded ? 'Скрыть' : 'Показать полностью'}
            <span className={`${isExpanded ? styles.arrowUp : styles.arrowDown}`}>
              <img src={arrowDropdownIcon} alt="" />
            </span>
          </button>
        </div>
      )}
    </div>
  );
};
