import React, {FC, useEffect, useState, useMemo, Dispatch, SetStateAction} from 'react';
import L, { LatLngBounds } from 'leaflet';
import styles from './GeneralPlanMap.module.scss';
import { BaseMapContainer, FloorPlanOverlay, FitBoundsOfPolygons } from '../../../../../../components/atoms';
import { observer } from 'mobx-react';
import { villageStore, infoStore } from '../../../../../../stores';
import { PlanToggle } from '../PlanToggle/PlanToggle';
import { Cottage, Section } from '../../../../../../services/v1/villageService/types';
import {useHistory, useParams} from 'react-router-dom';
import { Tooltip } from 'react-leaflet';
import { appRoutesService, BuildingSearchParameters, DictionaryKeys, Params } from '../../../../../../services';
import { CottagesOnGeneralPlan } from '../CottagesOnGeneralPlan/CottagesOnGeneralPlan';
import { PolygonCoordinates } from '../../../../../../types/PolygonCoordinates';
import { CottageInfoTooltip } from '../CottageInfoTooltip/CottageInfoTooltip';
import { CottageView } from "../CottageView/CottageView";
import { NoResults } from "../NoResults/NoResults"; // Импортируем ApartmentView

interface RouteParams {
  complexId: string;
  generalPlanId: string;
  viewType: string;
  sectionId: string;
  cottageId?: string;
  cottageNumber?: string;
}

interface GeneralPlanMapProps {
  villageId: string;
  sections: Section[];
  selectedSectionId: number | null;
  setSelectedSectionId: Dispatch<SetStateAction<number | null>>;
  filtersForAPI?: Partial<BuildingSearchParameters>;
}

export const GeneralPlanMap: FC<GeneralPlanMapProps> = observer((
  {
    villageId,
    sections,
    selectedSectionId,
    setSelectedSectionId,
    filtersForAPI,
  }) => {
  const [cottages, setCottages] = useState<Cottage[]>([]);
  const [loadingCottages, setLoadingCottages] = useState<boolean>(false);
  const [activeCottageIndex, setActiveCottageIndex] = useState<number | null>(null);
  const [isApartmentViewOpen, setIsApartmentViewOpen] = useState<boolean>(false);
  const [selectedCottage, setSelectedCottage] = useState<Cottage | null>(null);

  const history = useHistory();
  const params = useParams<RouteParams>();

  useEffect(() => {
    if (sections.length > 0 && selectedSectionId === null) {
      const sectionWithCottages = sections.find((section) => section.stats.apartments > 0);
      if (sectionWithCottages) {
        setSelectedSectionId(sectionWithCottages.id);
      } else {
        setSelectedSectionId(sections[0].id);
      }
    }
  }, [sections, selectedSectionId]);

  useEffect(() => {
    if (selectedSectionId !== null) {
      setLoadingCottages(true);
      villageStore
        .fetchCottages({
          complexIds: [villageId],
          sectionIds: [selectedSectionId],
          limit: 10000,
          ...filtersForAPI,
        })
        .then(() => {
          setCottages(villageStore.cottages);
          setLoadingCottages(false);
        });
    }
  }, [selectedSectionId, villageId, filtersForAPI]);

  const sectionCounts = useMemo(() => {
    const counts: { [key: number]: number } = {};
    sections.forEach((section) => (counts[section.id] = section.stats.apartments || 0));
    return counts;
  }, [sections]);

  const villagePlan = villageStore?.villageDetails?.sectionLogo?.url;
  const [baseBoundsOfPlan, setBaseBoundsOfPlan] = useState<LatLngBounds | undefined>(undefined);

  const boundsOfPolygons = baseBoundsOfPlan ? ([baseBoundsOfPlan] as unknown as PolygonCoordinates[][]) : undefined;

  const cottagePolygons = villageStore?.villageDetails?.cottagePolygons || [];

  const getDictionaryValues = (dictionaryKey: DictionaryKeys, id: number | undefined): string => {
    if (!id) return '-';
    const dict = infoStore?.dictionaries[dictionaryKey];
    const item = dict?.items.find((item) => item.id === id);
    return item ? item.value : '-';
  };

  const cottagesWithPolygons = useMemo(() => {
    return cottagePolygons
      .filter((item) => item.section_id === selectedSectionId)
      .map((item) => {
        const polygonStr = item.polygon;
        const cottageData = cottages.find((cottage) => cottage.id === item.apartment_id);
        if (polygonStr && cottageData) {
          try {
            const parsedPolygon = JSON.parse(polygonStr) as [number, number][];
            const reversedPolygon = parsedPolygon.map(([x, y]) => [y, x] as [number, number]);
            const statusId = item.apartment_status_type_id;
            const status = getDictionaryValues('apartment_status', statusId);

            let color = '#C7C8CB';
            if (status === 'В продаже' || status === 'Свободно') color = '#18EC74';
            else if (status === 'Бронь' || status === 'Резерв') color = '#C7C8CB';
            else if (status === 'Продана') color = '#FF4C4D';

            return {
              id: item.id,
              number: item.apartment_number,
              polygon: reversedPolygon,
              color,
              cottageData,
            };
          } catch (error) {
            console.error('Ошибка при парсинге полигона:', error);
          }
        }
        return null;
      })
      .filter(
        (
          item
        ): item is {
          id: number;
          number: string;
          polygon: [number, number][];
          color: string;
          cottageData: Cottage;
        } => item !== null
      );
  }, [cottagePolygons, selectedSectionId, cottages]);

  useEffect(() => {
    if (params.cottageId && cottagesWithPolygons.length > 0) {
      const cottagePolygon = cottagesWithPolygons.find(
        (item) => item.cottageData.id === Number(params.cottageId)
      );
      if (cottagePolygon) {
        const index = cottagesWithPolygons.indexOf(cottagePolygon);
        setActiveCottageIndex(index);
        setSelectedCottage(cottagePolygon.cottageData);
        setIsApartmentViewOpen(true);
      }
    }
  }, [cottagesWithPolygons, params.cottageId]);

  const onPolygonClick = (index: number) => (event: L.LeafletMouseEvent) => {
    setActiveCottageIndex(index);
    const cottageInfo = cottagesWithPolygons[index].cottageData;
    setSelectedCottage(cottageInfo);
    setIsApartmentViewOpen(true);

    // Обновляем маршрут с добавлением cottageId и cottageNumber
    const newParams = {
      ...params,
      cottageId: cottageInfo.id.toString(),
      cottageNumber: cottageInfo.number,
    };
    const newRoute = appRoutesService.replaceRoute('generalPlanCottage', newParams);
    history.push(newRoute);
  };

  const closeApartmentView = () => {
    setActiveCottageIndex(null);
    setIsApartmentViewOpen(false);
    setSelectedCottage(null);

    // Удаляем параметры cottageId и cottageNumber из маршрута
    const { cottageId, cottageNumber, ...restParams } = params;
    const newRoute = appRoutesService.replaceRoute('generalPlan', restParams);
    history.push(newRoute);
  };

  const areFiltersApplied = filtersForAPI && Object.keys(filtersForAPI).length > 0;

  if (cottages.length === 0 && !loadingCottages && areFiltersApplied) {
    return (
      <NoResults />
    );
  }

  return (
    <div className={styles.generalPlanMap}>
      <PlanToggle
        sections={sections}
        selectedSectionId={selectedSectionId}
        onSelectSection={(sectionId) => {
          if (sectionId !== selectedSectionId) {
            setSelectedSectionId(sectionId);
            setCottages([]);
            setActiveCottageIndex(null);
            setIsApartmentViewOpen(false);
          }
        }}
        sectionCounts={sectionCounts}
        className={styles.genPlanToggle}
      />

      <BaseMapContainer
        crs={L.CRS.Simple}
        minZoom={0}
        maxBoundsViscosity={0}
        className={styles.generalPlanMapContainer}
      >
        <div
          className={styles.backgroundImage}
          style={{
            backgroundImage: `url(${villagePlan})`,
          }}
        />
        <FloorPlanOverlay
          onChangePlanImage={setBaseBoundsOfPlan}
          fitBoundsOfPlan={false}
          imageUrl={villagePlan || ''}
        />

        <FitBoundsOfPolygons delay={200} polygons={boundsOfPolygons}/>

        {cottagesWithPolygons && (
          <CottagesOnGeneralPlan
            cottagePosition={cottagesWithPolygons.map((item) => item.polygon)}
            cottageColors={cottagesWithPolygons.map((item) => item.color)}
            activeCottageIndex={activeCottageIndex}
            onPolygonClick={onPolygonClick}
            polygonChildrenRender={(_, index) => {
              const cottageInfo = cottagesWithPolygons[index].cottageData;
              return (
                <Tooltip direction="top" opacity={1} className={styles.generalPlanTooltip}>
                  <CottageInfoTooltip cottage={cottageInfo}/>
                </Tooltip>
              );
            }}
          />
        )}
      </BaseMapContainer>

      {selectedCottage && (
        <CottageView
          onClose={closeApartmentView}
          apartment={selectedCottage}
          isOpen={isApartmentViewOpen}
        />
      )}
    </div>
  );
});


