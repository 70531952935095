import React, { useState, ReactNode } from 'react';
import cc from 'classcat';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import { Checkbox } from 'components/atoms/Checkbox';

import styles from './SelectSingle.module.css';
import { TagButton } from '../TagButton';
import { useTheme } from "../../../context/theme/useTheme";

/** @deprecated */
const DEFAULT_DROPDOWN_STATE = false;

/** @deprecated */
type Props = {
  items: Record<string, string>;
  value: Array<string | number> | null;
  placeholder: string;
  onChange: (newValue: Array<string | number> | null) => void;
  multiselect?: boolean;
  onlyInternal?: boolean;
  children?: any;
  disabled?: boolean;
};

type SelectIntervalOverlayProps = {
  items: Record<string, string>;
  multiselect?: boolean;
  handleChangeMultiselect: (value: string) => void;
  handleChangeSingle: (value: string) => void;
  value: Array<string | number> | null;
};

export const SelectIntervalOverlay = ({
  items,
  multiselect,
  handleChangeSingle,
  handleChangeMultiselect,
  value,
}: SelectIntervalOverlayProps) => (
  <>
    {Object.entries(items)
      .sort()
      .map(([itemValue, item]) => (
        <li className={styles.option} key={itemValue}>
          <Checkbox
            checked={Boolean(value?.includes(itemValue))}
            name={`${itemValue}_${item}`}
            onChange={() =>
              multiselect
                ? handleChangeMultiselect(itemValue)
                : handleChangeSingle(itemValue)
            }
            label={item}
          />
        </li>
      ))}
  </>
);

export const SelectSingle = ({
  onlyInternal = false,
  multiselect = false,
  items,
  value,
  placeholder,
  onChange,
  children,
  disabled,
}: Props) => {
  const [dropdownVisible, toggleDropdownVisibility] = useState(
    DEFAULT_DROPDOWN_STATE,
  );

  const { theme } = useTheme();

  const handleChangeMultiselect = (newValue: string) => {
    if (value === null) {
      onChange([newValue]);
      return;
    }

    if (value.includes(newValue) && value.length === 1) {
      onChange(null);
      return;
    }

    if (value.includes(newValue)) {
      onChange(value.filter((i) => i !== newValue));
      return;
    }

    onChange([...value, newValue]);
  };

  const handleChangeSingle = (newValue: string) => {
    if (value === null) {
      onChange([newValue]);
      return;
    }

    if (value.includes(newValue)) {
      onChange(null);
      return;
    }

    onChange([newValue]);
  };

  const displayingValue: ReactNode = value ? (
    value
      .map((id) => (
        <TagButton
          key={items[id]}
          type="button"
          isSelected
          disabled={disabled}
          className="selectedButton"
          onDelete={(e) => {
            e.stopPropagation();
            multiselect
                ? handleChangeMultiselect(String(id))
                : handleChangeSingle(String(id))
           }}>
            {items[id]}
        </TagButton>
      ))
  ) : (
    <span className={styles.placeholder}>{placeholder}</span>
  );

  if (onlyInternal) {
    return (
      <SelectIntervalOverlay
        items={items}
        multiselect={multiselect}
        handleChangeMultiselect={handleChangeMultiselect}
        handleChangeSingle={handleChangeSingle}
        value={value}
      />
    );
  }

  const handleVisibilityChange = (value: boolean) => {
    if (!disabled) {
      toggleDropdownVisibility(value);
    }
  };

  return (
    <Dropdown
      trigger={['click']}
      overlayClassName={cc([styles.overlayWrapper, theme])}
      onVisibleChange={handleVisibilityChange}
      visible={dropdownVisible}
      overlay={
        <ul className={styles.dropdownOverlay}>
          <SelectIntervalOverlay
            items={items}
            multiselect={multiselect}
            handleChangeMultiselect={handleChangeMultiselect}
            handleChangeSingle={handleChangeSingle}
            value={value}
          />
        </ul>
      }
      align={{ offset: [0, -1] }}
    >
      <div className={disabled ? styles.disabledContainer : ''}>
        {children}
        {!children && (
          <div
            className={cc([
              styles.root,
              {
                [styles.disabled]: disabled,
                [styles.withTags]: value,
                [styles.isOpen]: dropdownVisible,
              },
            ])}
          >
            {displayingValue}
          </div>
        )}
      </div>
    </Dropdown>
  );
};
