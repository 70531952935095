import React, { FC } from 'react';
import searchIcon from '../../assets/search-blue-icon.svg';
import styles from './NoResults.module.scss';
import { buildingFilters } from "../../../../../../stores";

export const NoResults: FC = () => {
  return (
    <div className={styles.noResultsContainer}>
      <div className={styles.icon}><img src={searchIcon} alt='' /></div>
      <div className={styles.message}>Для вашего запроса нет результатов</div>
      <div className={styles.suggestion}>
        Попробуйте изменить фильтры или <span onClick={buildingFilters.filters.reset} className={styles.resetLink}>сбросить фильтры</span>
      </div>
    </div>
  );
};
