import React, { ReactNode } from 'react';
import cc from 'classcat';
import { Text } from '../Text';
import styles from './styles.module.css';

type Props = {
  severity?: 'error' | 'primary' | 'standard';
  children?: ReactNode;
  isShow?: boolean;
  className?: string;
  icon?: string;
};

export const Alert = ({
  severity = 'error',
  children,
  isShow,
  className,
  icon,
}: Props) => {
  if (!isShow) return null;

  return (
    <div
      className={cc([
        styles.wrapper,
        {
          [styles.error]: severity === 'error',
          [styles.primary]: severity === 'primary',
          [styles.standard]: severity === 'standard',
        },
        className,
      ])}
    >
      {icon && <img src={icon} alt={severity} className={styles.icon}/>}
      <Text tagName="div" size="M" lineHeight="M" align="left">
        {children}
      </Text>
    </div>
  );
};
