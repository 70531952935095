import React from 'react';
import { useTheme } from "../../context/theme/useTheme";

const MailIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.9319 12.0772H9.84336V11.4234C9.23799 11.9924 8.48733 12.277 7.59137 12.277C6.36852 12.277 5.39387 11.8835 4.66742 11.0965C3.94098 10.3216 3.57775 9.28642 3.57775 7.99092C3.57775 6.76807 4.01967 5.75104 4.90352 4.93984C5.79947 4.11653 6.91941 3.70488 8.26334 3.70488C9.6678 3.70488 10.8907 4.03178 11.9319 4.68559V10.1339H12.2951C12.8036 10.1339 13.2213 9.85547 13.5482 9.29852C13.8872 8.74158 14.0568 8.01513 14.0568 7.11918C14.0568 5.6784 13.518 4.45554 12.4404 3.45062C11.3628 2.44571 9.94627 1.94325 8.19069 1.94325C6.4109 1.94325 4.92773 2.49414 3.7412 3.59591C2.55467 4.7098 1.96141 6.1748 1.96141 7.99092C1.96141 9.72229 2.56073 11.1631 3.75936 12.3133C4.98222 13.4635 6.45933 14.0386 8.19069 14.0386C8.88082 14.0386 9.53462 13.9659 10.1521 13.8207L10.2429 15.7639C9.56489 15.9213 8.88082 16 8.19069 16C5.82974 16 3.87439 15.2433 2.32463 13.7299C0.774877 12.2164 0 10.3034 0 7.99092C0 5.75104 0.780931 3.85622 2.34279 2.30647C3.89255 0.768823 5.84185 0 8.19069 0C10.4548 0 12.3193 0.659856 13.7843 1.97957C15.2614 3.28717 16 5.00038 16 7.11918C16 8.4389 15.6368 9.58305 14.9103 10.5516C14.2081 11.5081 13.2153 12.0166 11.9319 12.0772ZM7.97276 10.3337C8.6871 10.3337 9.31063 10.0492 9.84336 9.48014V6.06583C9.29852 5.79947 8.7658 5.66629 8.24518 5.66629C7.49451 5.66629 6.90125 5.89633 6.46538 6.35641C6.02951 6.80439 5.81158 7.37949 5.81158 8.08173C5.81158 8.77185 6.0053 9.32274 6.39274 9.73439C6.79228 10.1339 7.31896 10.3337 7.97276 10.3337Z"
          fill="#585B68"/>
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.9319 12.0772H9.84336V11.4234C9.23799 11.9924 8.48733 12.277 7.59137 12.277C6.36852 12.277 5.39387 11.8835 4.66742 11.0965C3.94098 10.3216 3.57775 9.28642 3.57775 7.99092C3.57775 6.76807 4.01967 5.75104 4.90352 4.93984C5.79947 4.11653 6.91941 3.70488 8.26334 3.70488C9.6678 3.70488 10.8907 4.03178 11.9319 4.68559V10.1339H12.2951C12.8036 10.1339 13.2213 9.85547 13.5482 9.29852C13.8872 8.74158 14.0568 8.01513 14.0568 7.11918C14.0568 5.6784 13.518 4.45554 12.4404 3.45062C11.3628 2.44571 9.94627 1.94325 8.19069 1.94325C6.4109 1.94325 4.92773 2.49414 3.7412 3.59591C2.55467 4.7098 1.96141 6.1748 1.96141 7.99092C1.96141 9.72229 2.56073 11.1631 3.75936 12.3133C4.98222 13.4635 6.45933 14.0386 8.19069 14.0386C8.88082 14.0386 9.53462 13.9659 10.1521 13.8207L10.2429 15.7639C9.56489 15.9213 8.88082 16 8.19069 16C5.82974 16 3.87439 15.2433 2.32463 13.7299C0.774877 12.2164 0 10.3034 0 7.99092C0 5.75104 0.780931 3.85622 2.34279 2.30647C3.89255 0.768823 5.84185 0 8.19069 0C10.4548 0 12.3193 0.659856 13.7843 1.97957C15.2614 3.28717 16 5.00038 16 7.11918C16 8.4389 15.6368 9.58305 14.9103 10.5516C14.2081 11.5081 13.2153 12.0166 11.9319 12.0772ZM7.97276 10.3337C8.6871 10.3337 9.31063 10.0492 9.84336 9.48014V6.06583C9.29852 5.79947 8.7658 5.66629 8.24518 5.66629C7.49451 5.66629 6.90125 5.89633 6.46538 6.35641C6.02951 6.80439 5.81158 7.37949 5.81158 8.08173C5.81158 8.77185 6.0053 9.32274 6.39274 9.73439C6.79228 10.1339 7.31896 10.3337 7.97276 10.3337Z"
          fill="#AFB4CB"/>
      </svg>
    )
  );
};

export default MailIcon;
