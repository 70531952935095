import React from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  currentActiveTab: number;
  children: React.ReactNode;
  className?: string;
  tabCount: number;
};

export const TabList = ({
  children,
  currentActiveTab,
  className,
  tabCount,
  ...rest
}: Props) => {
  const childrenArray = React.Children.toArray(children);

  const indicatorWidth = 100 / tabCount;
  const indicatorLeft = (currentActiveTab * 100) / tabCount;

  return (
    <div {...rest} className={cc([styles.tabListContainer, className])}>
      <div className={styles.tabList}>
        {childrenArray.map((child, index) => (
          <React.Fragment key={index}>
            {child}
            {index < childrenArray.length - 1 && <div className={styles.separator} />}
          </React.Fragment>
        ))}
        <div
          className={styles.activeIndicator}
          style={{
            width: `calc(${indicatorWidth}% + 3px)`,
            left: `calc(${indicatorLeft}% - ${currentActiveTab === 0 ? '0px' : '3px'})`,
          }}
        />
      </div>
    </div>
  );
};


