import React from 'react';
import cc from 'classcat';
import styles from './TagButtonGroup.module.scss';

type Props = {
  onClick?: () => void;
  children: React.ReactNode;
  className?: string;
  isSelected?: boolean;
  disabled?: boolean;
};

export const TagButtonGroup = ({
  onClick,
  className = '',
  children,
  isSelected,
  disabled,
}: Props) => (
  <button
    type="button"
    onClick={onClick}
    className={cc([
      styles.filterButton,
      {
        [styles.selectedButton]: isSelected,
        [styles.disabledButton]: disabled,
      },
      className,
    ])}
    disabled={disabled}
  >
    {children}
  </button>
);

