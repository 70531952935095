import React from 'react';
import ReactJoin from 'react-join';
import { NumberFormat } from 'components/atoms';
import { MayBe } from '../../../../../../types';
import UserIcon from "../../../../../../components/ui/UserIcon";
import PhoneIcon from "../../../../../../components/ui/PhoneIcon";
import MailIcon from "../../../../../../components/ui/MailIcon";
import styles from './styles.module.css';

type Props = {
  name?: MayBe<string>;
  emails?: MayBe<string[]>;
  phones?: MayBe<string[]>;
};

export const OffersContact = ({ name, phones, emails }: Props) => (
  <div className={styles.offersContactWrapper}>
    <div className={styles.offersContactBlock}>
      <div className={styles.offersContactClientIcon}>
        <UserIcon />
      </div>
      <div className={styles.offersContactClientName}>{name}</div>
    </div>
    {phones && (
      <div className={styles.offersContactBlock}>
        <div className={styles.offersContactClientIcon}>
          <PhoneIcon/>
        </div>
        <div>
          <ReactJoin separator={<span>, </span>}>
            {phones.map((phone, index) => (
              <NumberFormat
                key={index.toString()}
                format="mobile"
                value={phone}
              />
            ))}
          </ReactJoin>
        </div>
      </div>
    )}
    {emails && <div className={styles.offersContactBlock}>
      <div className={styles.offersContactClientIcon}>
        <MailIcon />
      </div>
      {emails.join(', ')}
    </div>}
      </div>
    );
