import React from 'react';
import { useTheme } from "../../context/theme/useTheme";

const UserIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 13C3.46878 12.3983 4.18408 11.8929 5.06662 11.5399C5.94916 11.1869 6.96447 11 8 11C9.03553 11 10.0508 11.1869 10.9334 11.5399C11.8159 11.8929 12.5312 12.3983 13 13M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM11 7C11 8.65685 9.65685 10 8 10C6.34315 10 5 8.65685 5 7C5 5.34315 6.34315 4 8 4C9.65685 4 11 5.34315 11 7Z"
          stroke="#585B68" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3 13C3.46878 12.3983 4.18408 11.8929 5.06662 11.5399C5.94916 11.1869 6.96447 11 8 11C9.03553 11 10.0508 11.1869 10.9334 11.5399C11.8159 11.8929 12.5312 12.3983 13 13M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM11 7C11 8.65685 9.65685 10 8 10C6.34315 10 5 8.65685 5 7C5 5.34315 6.34315 4 8 4C9.65685 4 11 5.34315 11 7Z"
          stroke="#AFB4CB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  );
};

export default UserIcon;
