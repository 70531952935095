import { API_ROUTES } from 'services/constants';
import { Album } from './types';
import { makeRequest } from '../../../libs/axios/makeRequest';
import { loggerService } from '../../../loggerService';
import { AttemptResult, ThrowableResult } from '../../../types';
import { attempt } from '../../../libs/axios/attempt';

export const albumCottageService = {
  getAlbums: async (cottageId: string): Promise<AttemptResult<Album[]>> =>
    attempt<Album[]>({
      url: API_ROUTES.cottageAlbums.replace(':cottageId', cottageId),
      method: 'get',
    }),
  getAlbum: async (albumId: string): Promise<ThrowableResult<Album[]>> => {
    try {
      const response = await makeRequest<Album[]>({
        url: API_ROUTES.cottageAlbum.replace(':albumId', albumId),
        method: 'get',
      });
      return response.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
};
