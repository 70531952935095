import React from 'react';
import { useTheme } from "../../context/theme/useTheme";

const PdfIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5 4V9H3V3.75C3 3.2643 3.20639 2.81262 3.55034 2.49015C3.89212 2.16972 4.34265 2 4.79996 2H15C15.2652 2 15.5196 2.10536 15.7071 2.29289L20.7071 7.29289C20.8946 7.48043 21 7.73478 21 8L21 20.25C21 20.4891 20.9497 20.7241 20.8538 20.9411C20.758 21.1579 20.6196 21.3506 20.4497 21.5098C20.2799 21.669 20.0818 21.7921 19.8682 21.8751C19.6546 21.958 19.4278 22 19.2 22H4.79996C4.34265 22 3.89212 21.8303 3.55034 21.5099C3.20639 21.1874 3 20.7357 3 20.25V18H5V20H19L19 9H14C13.4477 9 13 8.55228 13 8V4H5ZM15 4.41421V7H17.5858L15 4.41421Z"
              fill="#E1E4EC"/>
        <path
          d="M3 16V11H5.14673C5.51585 11 5.83884 11.0732 6.11568 11.2197C6.39252 11.3662 6.60784 11.5721 6.76164 11.8374C6.91544 12.1027 6.99234 12.4128 6.99234 12.7676C6.99234 13.1257 6.91301 13.4357 6.75435 13.6978C6.59732 13.9598 6.37633 14.1616 6.09139 14.3032C5.80808 14.4448 5.477 14.5156 5.09816 14.5156H3.81595V13.4609H4.82618C4.98484 13.4609 5.12002 13.4333 5.23173 13.3779C5.34506 13.321 5.43167 13.2404 5.49157 13.1362C5.55309 13.0321 5.58385 12.9092 5.58385 12.7676C5.58385 12.6243 5.55309 12.5023 5.49157 12.4014C5.43167 12.2988 5.34506 12.2207 5.23173 12.167C5.12002 12.1117 4.98484 12.084 4.82618 12.084H4.35021V16H3Z"
          fill="#E1E4EC"/>
        <path
          d="M9.40135 16H7.48774V11H9.38192C9.89351 11 10.3355 11.1001 10.7078 11.3003C11.0818 11.4989 11.37 11.7853 11.5724 12.1597C11.7764 12.5324 11.8783 12.9792 11.8783 13.5C11.8783 14.0208 11.7772 14.4684 11.5748 14.8428C11.3724 15.2155 11.0859 15.502 10.7151 15.7021C10.3444 15.9007 9.90646 16 9.40135 16ZM8.83795 14.8477H9.35278C9.59886 14.8477 9.80851 14.8078 9.98174 14.728C10.1566 14.6483 10.2893 14.5107 10.38 14.3154C10.4723 14.1201 10.5184 13.8483 10.5184 13.5C10.5184 13.1517 10.4715 12.8799 10.3776 12.6846C10.2853 12.4893 10.1493 12.3517 9.9696 12.272C9.79151 12.1922 9.57295 12.1523 9.31392 12.1523H8.83795V14.8477Z"
          fill="#E1E4EC"/>
        <path d="M12.5031 16V11H16V12.0938H13.8533V12.9531H15.7863V14.0469H13.8533V16H12.5031Z" fill="#E1E4EC"/>
      </svg>
    ) : (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M5 4V9H3V3.75C3 3.2643 3.20639 2.81262 3.55034 2.49015C3.89212 2.16972 4.34265 2 4.79996 2H15C15.2652 2 15.5196 2.10536 15.7071 2.29289L20.7071 7.29289C20.8946 7.48043 21 7.73478 21 8L21 20.25C21 20.4891 20.9497 20.7241 20.8538 20.9411C20.758 21.1579 20.6196 21.3506 20.4497 21.5098C20.2799 21.669 20.0818 21.7921 19.8682 21.8751C19.6546 21.958 19.4278 22 19.2 22H4.79996C4.34265 22 3.89212 21.8303 3.55034 21.5099C3.20639 21.1874 3 20.7357 3 20.25V18H5V20H19L19 9H14C13.4477 9 13 8.55228 13 8V4H5ZM15 4.41421V7H17.5858L15 4.41421Z"
              fill="#333338"/>
        <path
          d="M3 16V11H5.14673C5.51585 11 5.83884 11.0732 6.11568 11.2197C6.39252 11.3662 6.60784 11.5721 6.76164 11.8374C6.91544 12.1027 6.99234 12.4128 6.99234 12.7676C6.99234 13.1257 6.91301 13.4357 6.75435 13.6978C6.59732 13.9598 6.37633 14.1616 6.09139 14.3032C5.80808 14.4448 5.477 14.5156 5.09816 14.5156H3.81595V13.4609H4.82618C4.98484 13.4609 5.12002 13.4333 5.23173 13.3779C5.34506 13.321 5.43167 13.2404 5.49157 13.1362C5.55309 13.0321 5.58385 12.9092 5.58385 12.7676C5.58385 12.6243 5.55309 12.5023 5.49157 12.4014C5.43167 12.2988 5.34506 12.2207 5.23173 12.167C5.12002 12.1117 4.98484 12.084 4.82618 12.084H4.35021V16H3Z"
          fill="#333338"/>
        <path
          d="M9.40135 16H7.48774V11H9.38192C9.89351 11 10.3355 11.1001 10.7078 11.3003C11.0818 11.4989 11.37 11.7853 11.5724 12.1597C11.7764 12.5324 11.8783 12.9792 11.8783 13.5C11.8783 14.0208 11.7772 14.4684 11.5748 14.8428C11.3724 15.2155 11.0859 15.502 10.7151 15.7021C10.3444 15.9007 9.90646 16 9.40135 16ZM8.83795 14.8477H9.35278C9.59886 14.8477 9.80851 14.8078 9.98174 14.728C10.1566 14.6483 10.2893 14.5107 10.38 14.3154C10.4723 14.1201 10.5184 13.8483 10.5184 13.5C10.5184 13.1517 10.4715 12.8799 10.3776 12.6846C10.2853 12.4893 10.1493 12.3517 9.9696 12.272C9.79151 12.1922 9.57295 12.1523 9.31392 12.1523H8.83795V14.8477Z"
          fill="#333338"/>
        <path d="M12.5031 16V11H16V12.0938H13.8533V12.9531H15.7863V14.0469H13.8533V16H12.5031Z" fill="#333338"/>
      </svg>
    )
  );
};

export default PdfIcon;
