import React from 'react';
import { flow, get } from 'lodash/fp';
import { Input } from 'components/atoms/Form/Input';
import SearchIcon from "../../../../../../components/ui/SearchIcon";
import styles from "./styles.module.scss";

type Props = {
  value?: string;
  onChange: (value?: string) => void;
};

export const OffersSearch = ({ value, onChange }: Props) => (
  <Input
    customIcon={<SearchIcon />}
    placeholder="Поиск города..."
    value={value}
    onChange={flow(get(['target', 'value']), onChange)}
    className={styles.searchWrapper}
  />
);
