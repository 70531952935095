import { FC } from 'react';

import rewardIcon from '../../../assets/reward-icon.png';
import infoIcon from '../../../assets/info-icon.svg';
import styles from './Reward.module.scss';
import cc from "classcat";

interface Props {
  className?: string;
  count?: number;
  percent?: string | null;
}

export const Reward: FC<Props> = ({ className, count, percent }) => {
  const formattedPercent = percent ? Math.round(parseFloat(percent)) : null;

  return (
    <div className={cc([styles.reward, className])}>
      <div className={styles.iconWrapper}>
        <img src={rewardIcon} alt="Reward Icon" className={styles.rewardIcon} />
      </div>
      <div className={styles.content}>
        <div className={styles.amount}>{count ? count : ''} ₽</div>
        <div className={styles.description}>Агентское вознаграждение за этот объект</div>
      </div>
      <div className={styles.contentRight}>
        {
          percent && <span className={styles.percent}>{formattedPercent}%</span>
        }
        <img src={infoIcon} alt="Info Icon" className={styles.infoIcon} />
      </div>
    </div>
  );
};
