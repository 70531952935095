import { makeAutoObservable, runInAction } from 'mobx';
import { villageService } from '../../services/v1/villageService';
import {
  VillageDetails,
  Cottage,
  CottageDetails,
} from '../../services/v1/villageService/types';

class VillageStore {
  villageDetails: VillageDetails | null = null;
  cottages: Cottage[] = [];
  cottageDetails: CottageDetails | null = null;
  loadingVillage = false;
  loadingCottages = false;
  loadingCottageDetails = false;

  constructor() {
    makeAutoObservable(this);
  }

  isSectionEmpty(sectionId: number): boolean {
    const cottagesInSection = this.cottages.filter((cottage) => cottage.section_id === sectionId);
    return cottagesInSection.length === 0;
  }

  async fetchVillageDetails(villageId: string) {
    this.loadingVillage = true;
    try {
      const data = await villageService.getVillageDetails(villageId);
      runInAction(() => {
        this.villageDetails = data;
      });
    } catch (error) {
      console.error('Ошибка при загрузке данных поселка:', error);
    } finally {
      runInAction(() => {
        this.loadingVillage = false;
      });
    }
  }

  async fetchCottages(params: Record<string, any>) {
    this.loadingCottages = true;
    try {
      const { filtersForAPI, ...restParams } = params;

      const data = await villageService.getCottages(restParams, filtersForAPI);
      runInAction(() => {
        this.cottages = data.data;
      });
    } catch (error) {
      console.error('Ошибка при загрузке коттеджей:', error);
    } finally {
      runInAction(() => {
        this.loadingCottages = false;
      });
    }
  }

  async fetchCottageDetails(apartmentId: string) {
    this.loadingCottageDetails = true;
    try {
      const data = await villageService.getCottageDetails(apartmentId);
      runInAction(() => {
        this.cottageDetails = data;
      });
    } catch (error) {
      console.error('Ошибка при загрузке данных коттеджа:', error);
    } finally {
      runInAction(() => {
        this.loadingCottageDetails = false;
      });
    }
  }
}

export const villageStore = new VillageStore();
