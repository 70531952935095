import React, { FC } from 'react';
import obSaleItem from '../../assets/on-sale-item.svg';
import reservationIcon from '../../assets/reservation-icon.svg';
import soldIcon from '../../assets/sold-icon.svg';
import styles from './TableRow.module.scss';
import cc from "classcat";
import { Cottage } from '../../../../../../services/v1/villageService/types';
import { observer } from 'mobx-react';
import { infoStore } from 'stores';
import { DictionaryKeys } from 'services';
import {OffersStatusAndAddButton} from "../../../../../../components/containers/OffersStatusAndAddButton";
import {FavoritesAddButton} from "../../../../../../components/containers/FavoritesAddButton";

interface TableRowProps {
  data: Cottage;
  onRowClick: (cottage: Cottage) => void;
}

export const TableRow: FC<TableRowProps> = observer(({ data, onRowClick }) => {
  // Функция для форматирования чисел (добавляет пробелы между тысячами)
  const formatValue = (value: number | string): string => {
    const strValue = String(value);
    return strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  // Функция для получения значений из словарей
  const getDictionaryValues = (dictionaryKey: DictionaryKeys, ids: number[] | undefined): string => {
    if (!ids || ids.length === 0) return '-';
    const dict = infoStore.dictionaries[dictionaryKey];
    if (!dict || !dict.items) return '-';
    const values = ids.map(id => {
      const item = dict.items.find(item => item.id === id);
      return item ? item.value : '-';
    });
    return values.join(', ');
  };

  // Получение статуса квартиры
  const statusId = data?.catalogs?.apartment_status?.[0];
  const status = statusId
    ? getDictionaryValues('apartment_status', [statusId])
    : '-';

  const statusClass =
    status === 'В продаже' || status === 'Свободно'
      ? styles.available
      : status === 'Бронь' || status === 'Резерв'
        ? styles.reserved
        : styles.sold;

  // Получение значений из словарей для других полей
  const villageTypeHouseValues = getDictionaryValues('village_type_house', data?.catalogs?.village_type_house);
  const villageMaterialValues = getDictionaryValues('village_material', data?.catalogs?.village_material);
  const residentialComplexFinishingValues = getDictionaryValues('residential_complex_finishing', data?.catalogs?.residential_complex_finishing);

  return (
    <div className={cc([styles.tableRow, statusClass])} onClick={() => onRowClick(data)}>
      <div className={cc([styles.tableCell, styles.tableCellStatus])}>
        {(status === 'В продаже' || status === 'Свободно') && <img src={obSaleItem} alt='Icon' />}
        {(status === 'Бронь' || status === 'Резерв') && <img src={reservationIcon} alt='Icon' />}
        {status === 'Продана' && <img src={soldIcon} alt='Icon' />}
        <span>{status}</span>
      </div>
      <div className={styles.tableCell}>
        {data.layouts != null && data.layouts[0]?.photo ? (
          <img className={styles.tableCellImg} src={data.layouts[0].photo} alt="" />
        ) : (
          <span>-</span>
        )}
      </div>
      <div className={styles.tableCell}>{data?.number ? data?.number : '-'}</div>
      <div className={styles.tableCell}>{data?.number_floors ? data?.number_floors : '-'}</div>
      <div className={styles.tableCell}>{data?.number_bedrooms ? data?.number_bedrooms : '-'}</div>
      <div className={styles.tableCell}>{villageTypeHouseValues}</div>
      <div className={styles.tableCell}>{data?.square_land ? `${data.square_land} сот` : '-'}</div>
      <div className={styles.tableCell}>{data?.square ? `${data.square} м²` : '-'}</div>
      <div className={styles.tableCell}>{data?.price_m2 ? `${formatValue(data.price_m2)} ₽` : '-'}</div>
      <div className={cc([styles.tableCell, styles.tableCellPrice])}>{data?.price ? `${formatValue(data.price)} ₽` : '-'}</div>
      <div className={styles.tableCell}>{villageMaterialValues}</div>
      <div className={styles.tableCell}>{residentialComplexFinishingValues}</div>
      <div className={styles.actionButtons} onClick={(event) => event.stopPropagation()} >
        <OffersStatusAndAddButton apartmentId={data?.id} />
        <FavoritesAddButton apartmentId={data?.id} />
      </div>
    </div>
  );
});

