import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Icon } from 'components/atoms/Icon';
import addOffers from 'assets/addOffersIcon.svg';
import { addToOffersStore } from 'stores/OffersStore/AddToOffersStore';
import { ButtonBase } from '../../../../../LayoutPage/components/ButtonBase';
import { OffersSearch } from '../../../OffersPage/components/OffersSearch';
import { useTheme } from "../../../../../../context/theme/useTheme";
import closeIconLight from "../../../../../../assets/closeIconLight.svg";
import closeIconDark from "../../../../../../assets/closeIconDark.svg";
import offerBlackIcon from 'assets/offersBlackIcon.svg';
import styles from './styles.module.css';

type Props = {
  onCreateNewOffer: () => void;
  onClose?: () => void;
};

export const AddToOffersHeaderRender = ({ onCreateNewOffer, onClose }: Props) => {
  const { theme } = useTheme();

  const [initialOffersExist, setInitialOffersExist] = useState(false);

  useEffect(() => {
    // Если появились офферы (больше 0), выставляем флаг.
    if (addToOffersStore.offers.length > 0) {
      setInitialOffersExist(true);
    }
  }, [addToOffersStore.offers.length]);

  return (
    <header className={styles.addToOffersHeaderWrapper}>
      <div className={styles.addToOffersHeaderWrapperInner}>
        <ButtonBase onClick={onClose} className={styles.closeButton}>
          <img
            src={theme === 'dark-theme' ? closeIconLight : closeIconDark}
            className={styles.closeButtonIcon}
            alt="close modal"
          />
        </ButtonBase>
        <div className={styles.addToOffersHeaderTop}>
          <h2 className={styles.addToOffersHeaderTitle}>Добавить в подборку</h2>
          <ButtonBase
            onClick={onCreateNewOffer}
            icon={<Icon src={addOffers} size={16}/>}
            className={styles.addOffersBtn}
          >
            Создать
          </ButtonBase>
        </div>
      </div>
      <div className={styles.addToOffersHeaderWrapperInnerMobile}>
        <ButtonBase onClick={onClose} className={styles.closeButton}>
          <img
            src={theme === 'dark-theme' ? closeIconLight : closeIconDark}
            className={styles.closeButtonIcon}
            alt="close modal"
          />
        </ButtonBase>
        <h2 className={styles.addToOffersHeaderTitle}>Добавить в подборку</h2>
        <button onClick={onCreateNewOffer}><Icon src={offerBlackIcon} size={16}/></button>
      </div>
      {initialOffersExist && (
        <div className={styles.addToOffersHeaderSearch}>
          <OffersSearch
            value={addToOffersStore.offersFilterStore.searchParams}
            onChange={addToOffersStore.offersFilterStore.setSearch}
          />
        </div>
      )}
    </header>
  )
};

export const AddToOffersHeader = observer(AddToOffersHeaderRender);
