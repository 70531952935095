import React from 'react';
import { Text } from 'components/atoms';
import arrowRightDark from '../../../assets/arrowRightDark.svg';
import arrowRightLight from '../../../assets/arrowRightLight.svg';
import styles from './LevelStepper.module.css';
import {useTheme} from "../../../../../../../context/theme/useTheme";

type Props = {
  onDecrement?: () => void;
  count: number;
  minCount: number;
  maxCount: number;
  onIncrement?: () => void;
};

export const LevelStepper = ({
  minCount,
  maxCount,
  onDecrement,
  onIncrement,
  count,
}: Props) => {
  const { theme } = useTheme();

  return (
    <div className={styles.wrapper}>
      <button
        disabled={count <= minCount}
        className={styles.button}
        type="button"
        onClick={onDecrement}
      >
        <img className={styles.buttonIcon} src={theme === 'dark-theme' ? arrowRightLight : arrowRightDark} alt=""/>
      </button>
      <Text className={styles.counter} size="XS" weight="bold">
        {count} уровень
      </Text>
      <button
        disabled={count >= maxCount}
        className={styles.button}
        type="button"
        onClick={onIncrement}
      >
        <img className={styles.buttonIcon} src={theme === 'dark-theme' ? arrowRightLight : arrowRightDark } alt=""/>
      </button>
    </div>
  )
};
