import React from 'react';
import { useTheme } from "../../context/theme/useTheme";

const AddFavoriteIcon = ({ isFavorite }) => {
  const { theme } = useTheme();
  return (
    isFavorite ?
      <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.15 2C9.932 2 8.763 2.57384 8 3.48065C7.237 2.57384 6.068 2 4.85 2C2.694 2 1 3.71444 1 5.89646C1 8.57439 3.38 10.7564 6.985 14.0719L8 15L9.015 14.0649C12.62 10.7564 15 8.57439 15 5.89646C15 3.71444 13.306 2 11.15 2Z" fill="#EA4848"/>
      </svg>
    : theme === 'dark-theme' ? (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5197 19.7848L19.5544 12.3432C21.2829 10.5148 21.5379 7.50664 19.9022 5.58506C19.4925 5.10234 18.9941 4.713 18.4374 4.44077C17.8807 4.16855 17.2775 4.01917 16.6644 4.00173C16.0514 3.98429 15.4414 4.09916 14.8717 4.33934C14.302 4.57952 13.7845 4.93997 13.3508 5.39869L12.0286 6.79738L10.887 5.58975C9.15855 3.76128 6.31491 3.49152 4.49839 5.22187C4.04207 5.6553 3.67401 6.18253 3.41667 6.7714C3.15934 7.36027 3.01812 7.99843 3.00163 8.64694C2.98515 9.29545 3.09374 9.94072 3.32079 10.5434C3.54783 11.146 3.88857 11.6934 4.32222 12.1522L11.5375 19.7848C11.6678 19.9226 11.8444 20 12.0286 20C12.2128 20 12.3895 19.9226 12.5197 19.7848V19.7848Z"
          stroke="#E1E4EC" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    ) : (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.5197 19.7848L19.5544 12.3432C21.2829 10.5148 21.5379 7.50664 19.9022 5.58506C19.4925 5.10234 18.9941 4.713 18.4374 4.44077C17.8807 4.16855 17.2775 4.01917 16.6644 4.00173C16.0514 3.98429 15.4414 4.09916 14.8717 4.33934C14.302 4.57952 13.7845 4.93997 13.3508 5.39869L12.0286 6.79738L10.887 5.58975C9.15855 3.76128 6.31491 3.49152 4.49839 5.22187C4.04207 5.6553 3.67401 6.18253 3.41667 6.7714C3.15934 7.36027 3.01812 7.99843 3.00163 8.64694C2.98515 9.29545 3.09374 9.94072 3.32079 10.5434C3.54783 11.146 3.88857 11.6934 4.32222 12.1522L11.5375 19.7848C11.6678 19.9226 11.8444 20 12.0286 20C12.2128 20 12.3895 19.9226 12.5197 19.7848V19.7848Z"
          stroke="#333338" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  );
};

export default AddFavoriteIcon;
