import React, { ReactElement } from 'react';
import { Polygon as PolygonComponent, useMap } from 'react-leaflet';
import { LeafletMouseEvent, Map, Polygon } from 'leaflet';
import { PolygonCoordinates } from 'types/PolygonCoordinates';

type Props = {
  cottagePosition: PolygonCoordinates[][];
  cottageColors: string[];
  activeCottageIndex?: number;
  onPolygonClick?: (index: number) => (event: LeafletMouseEvent) => void;
  onMouseOut?: (index: number) => void;
  onMouseOver?: (index: number) => void;
  polygonChildrenRender?: (latLng: PolygonCoordinates[], index: number) => ReactElement;
};

const polygonPanToCenter = (
  map: Map,
  activeCottageIndex: number | undefined,
  index: number
) => {
  let timeId: NodeJS.Timeout | undefined;
  const _clearTimeout = () => timeId && clearTimeout(timeId);

  return (ref: Polygon) => {
    _clearTimeout();
    if (!ref) {
      return;
    }
    if (activeCottageIndex === index) {
      timeId = setTimeout(() => {
        const center = ref.getCenter();
        map.panTo(center);
      }, 500);
    }
  };
};

export const CottagesOnGeneralPlan = ({
  cottagePosition,
  cottageColors,
  activeCottageIndex,
  onPolygonClick,
  onMouseOut,
  onMouseOver,
  polygonChildrenRender,
}: Props) => {
  const map = useMap();

  const getCottageColor = (index: number) => {
    if (activeCottageIndex === index || !cottageColors) {
      return '#4E6AFF';
    }
    return cottageColors[index];
  };

  const _onMouseOver = (index: number) => (event: LeafletMouseEvent) => {
    event.target.setStyle({ opacity: 1 });
    if (onMouseOut) {
      onMouseOut(index);
    }
  };

  const _onMouseOut = (index: number) => (event: LeafletMouseEvent) => {
    event.target.setStyle({ opacity: 1 });
    if (onMouseOver) {
      onMouseOver(index);
    }
  };

  return (
    <>
      {cottagePosition.map((latLng, index) => (
        <PolygonComponent
          ref={polygonPanToCenter(map, activeCottageIndex, index)}
          positions={latLng}
          key={index.toString()}
          pathOptions={{
            color: getCottageColor(index),
            fillOpacity: 0.4,
            opacity: 1,
          }}
          eventHandlers={{
            click: onPolygonClick && onPolygonClick(index),
            mouseover: _onMouseOver(index),
            mouseout: _onMouseOut(index),
          }}
        >
          {polygonChildrenRender && polygonChildrenRender(latLng, index)}
        </PolygonComponent>
      ))}
    </>
  );
};

