import React from 'react';
import { useTheme } from "../../context/theme/useTheme";

const SearchIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M18.3138 9.5319C18.3138 13.5536 15.0536 16.8138 11.0319 16.8138C7.01022 16.8138 3.75 13.5536 3.75 9.5319C3.75 5.51022 7.01022 2.25 11.0319 2.25C15.0536 2.25 18.3138 5.51022 18.3138 9.5319ZM15.1986 18.1073C13.9398 18.72 12.526 19.0638 11.0319 19.0638C5.76758 19.0638 1.5 14.7962 1.5 9.5319C1.5 4.26758 5.76758 0 11.0319 0C16.2962 0 20.5638 4.26758 20.5638 9.5319C20.5638 12.509 19.199 15.1673 17.0609 16.9153L21.9037 21.8047C22.3395 22.2447 22.3361 22.9546 21.8962 23.3904C21.4562 23.8262 20.7463 23.8228 20.3105 23.3828L15.4355 18.4609C15.3315 18.3559 15.2526 18.2356 15.1986 18.1073Z"
              fill="#585B68"/>
      </svg>
    ) : (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M18.3138 9.5319C18.3138 13.5536 15.0536 16.8138 11.0319 16.8138C7.01022 16.8138 3.75 13.5536 3.75 9.5319C3.75 5.51022 7.01022 2.25 11.0319 2.25C15.0536 2.25 18.3138 5.51022 18.3138 9.5319ZM15.1986 18.1073C13.9398 18.72 12.526 19.0638 11.0319 19.0638C5.76758 19.0638 1.5 14.7962 1.5 9.5319C1.5 4.26758 5.76758 0 11.0319 0C16.2962 0 20.5638 4.26758 20.5638 9.5319C20.5638 12.509 19.199 15.1673 17.0609 16.9153L21.9037 21.8047C22.3395 22.2447 22.3361 22.9546 21.8962 23.3904C21.4562 23.8262 20.7463 23.8228 20.3105 23.3828L15.4355 18.4609C15.3315 18.3559 15.2526 18.2356 15.1986 18.1073Z"
              fill="#AFB4CB"/>
      </svg>
    )
  );
};

export default SearchIcon;
