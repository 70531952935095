import React from 'react';
import { ContextValueVillageFilters } from 'context';
import { FirstColumnFilter } from '../Search/villages/FirstColumnFilters';
import styles from './AllFilters.module.scss';
import { SecondColumnFilter } from '../Search/villages/SecondColumnFilters';
import { ThirdColumnFilters } from '../Search/villages/ThirdColumnFilters';
import {complexFilterStore} from "../../../stores";
import {FilterBottomButtons} from "../AllFiltersPopup/components";

type Props = {
  isShowFromVillage?: boolean,
  store?: { filters: ContextValueVillageFilters['villageDataFilter'] },
  handleChangeStore?: ContextValueVillageFilters["handleChangeVillageFilter"],
}

export const VillageTab = ({ isShowFromVillage, store, handleChangeStore }: Props) => {
  return <>
    <div className={styles.formColumn}>
      <FirstColumnFilter
        isShowFromVillage={isShowFromVillage}
        store={store}
        handleChangeStore={handleChangeStore} />
    </div>

    {/*<div className={styles.formColumn}>*/}
    {/*  <SecondColumnFilter isShowFromVillage={isShowFromVillage}  store={store}*/}
    {/*    handleChangeStore={handleChangeStore} />*/}
    {/*</div>*/}

    {/*<div className={styles.formColumn}>*/}
    {/*  <ThirdColumnFilters*/}
    {/*    store={store}*/}
    {/*    handleChangeStore={handleChangeStore}*/}
    {/*  />*/}
    {/*</div>*/}
  </>
};
